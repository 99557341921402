import React, { useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";
import Cookie from "js-cookie";
import ReactEChartsCore from "echarts-for-react/lib/core";
import * as echarts from "echarts/core";

function Dashboard24HoursChart() {
  const [option, setOption] = useState({});

  useEffect(() => {
    async function fetchAlertsByDay() {
      const token = Cookie.get("token");
      try {
        const response = await fetch(
          "https://app.semon.io:8443/alerts_by_hours",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error(
            `Error fetching alerts by day: ${response.statusText}`
          );
        }

        const data = await response.json();
        const attacksData = [];
        const categories = [];
        const topAgentsData = [];

        data.forEach((item) => {
          const dateTimeString = item.hour;
          const dateObj = new Date(dateTimeString);
          const formattedHour = dateObj.toLocaleString("en-US", {
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          });

          categories.push(formattedHour);
          attacksData.push(item.counts.all_alerts);
          topAgentsData.push(item.counts.top_agents);
        });

        setOption({
          color: ["#148922"],
          grid: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            containLabel: true,
          },
          tooltip: {
            trigger: "axis",
            backgroundColor: "rgba(0,0,0, 0.8)",
            borderColor: "rgba(255,255,255, 0.2)",
            borderWidth: 1,
            padding: [5, 10],
            textStyle: {
              color: "#a8b6bc",
            },
            formatter: function (params) {
              const { dataIndex } = params[0];
              const agents = topAgentsData[dataIndex]
                .map((agent) => {
                  return `<tr><td style="padding-right: 20px">${agent.key}</td><td>${agent.doc_count}</td></tr>`;
                })
                .join("");
              return `<div style="text-align: left; min-width: 200px">
                    <strong>Time: ${categories[dataIndex]}</strong><br/>
                    <table style="margin-top: 8px; width: 100%">
                    <thead style="background-color: black; color: rgba(255,255,255, 0.3);">
                        <tr>
                            <th style="padding-right: 20px">Agent</th>
                            <th>Alerts</th>
                        </tr>
                    </thead>
                        ${agents}
                    </table>
                </div>`;
            },
          },
          xAxis: {
            type: "category",
            data: categories,
            axisLabel: {
              color: "rgba(30, 204, 51, 0.6",
            },
            axisLine: {
              lineStyle: {
                color: "rgba(30, 204, 51, 0.4)", // replace with your color
              },
            },
            axisTick: {
              lineStyle: {
                color: "rgba(30, 204, 51, 0.4)", // replace with your color
              },
            },
          },

          yAxis: {
            show: false,
            type: "log",
            axisLabel: {
              show: false,
            },
            axisTick: {
              show: false,
            },
          },

          series: [
            {
              data: attacksData,
              type: "bar",
              itemStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "rgba(30, 204, 51, 0.85)" },
                  { offset: 1, color: "rgba(106, 121, 133, 0.4)" },
                ]),
                borderWidth: 0.9,
                borderColor: "rgba(30, 204, 51, 0.6)",
                shadowColor: "rgba(30, 204, 51, 0.4)",
                shadowBlur: 10,
                shadowOffsetY: 10,
              },
            },
          ],
        });
      } catch (error) {
        console.error("Error fetching alerts by day:", error);
      }
    }

    fetchAlertsByDay();
  }, []);

  return (
    <div style={{ height: "200px", width: "100%" }}>
      <ReactECharts
        option={option}
        style={{ height: "100%", width: "100%" }}
        // onEvents={{
        //   click: function (params) {
        //     console.log("You clicked on ", params.name);
        //   },
        // }}
      />
    </div>
  );
}

export default Dashboard24HoursChart;

import { createContext, useState } from "react";

export const MitreIdsContext = createContext([]);

export const MitreIdsProvider = ({ children }) => {
  const [mitreIds, setMitreIds] = useState([]);

  return (
    <MitreIdsContext.Provider value={[mitreIds, setMitreIds]}>
      {children}
    </MitreIdsContext.Provider>
  );
};

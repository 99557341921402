import { AppSettings } from "../../config/app-settings";
import React, { useContext, useEffect, useState } from "react";
import { SearchQueryContext } from "../../context/SearchQueryContext";
import RightSidebar from "./RightSidebar";
import DatePicker from "../forms/DatePicker";
import { Link } from "react-router-dom";
import { FilterContext } from "../../context/FilterContext";

function RealTimeLogsDashboard(props) {
  const [searchQuery] = useContext(SearchQueryContext);
  const [logs, setLogs] = useState(props.logs);
  const [currentPage, setCurrentPage] = useState(1);
  const [logsPerPage, setLogsPerPage] = useState(50000);
  const [filterLevel, setFilterLevel] = useState(13);
  const [datat, setDate] = useState(null);
  const [selectedLogIndex, setSelectedLogIndex] = useState(null);
  const [filter, setFilter] = useContext(FilterContext);

  const handleLogClick = (index) => {
    setSelectedLogIndex(index === selectedLogIndex ? null : index);
  };

  const handleToggleSidebar = (index) => {
    setSelectedLogIndex(index === selectedLogIndex ? null : index);
    console.log(index);
  };
  useEffect(() => {
    function handlePageChange(page) {
      setCurrentPage(page);
      if (window.innerWidth < 768) {
        window.scrollTo(0, 0);
      }
    }
    const { innerWidth, innerHeight } = window;
    setLogs(props.logs);
  }, [searchQuery, filterLevel, datat, props.logs]);

  const indexOfLastLog = currentPage * logsPerPage;
  const indexOfFirstLog = indexOfLastLog - logsPerPage;
  const currentLogs = logs.slice(indexOfFirstLog, indexOfLastLog);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(logs.length / logsPerPage); i++) {
    pageNumbers.push(i);
  }

  function handlePageChange(page) {
    setCurrentPage(page);
    if (window.innerWidth < 768) {
      window.scrollTo(0, 0);
    }
  }
  function handleLogsPerPageChange(event) {
    setLogsPerPage(parseInt(event.target.value));
    setCurrentPage(1); // Reset the current page when changing the logs per page
  }
  function flattenJSONtoHTML(data, parentKey = "", isRoot = true) {
    let htmlElements = [];
    for (let key in data) {
      let newKey = parentKey ? `${parentKey}.${key}` : key;
      if (typeof data[key] !== "object" || data[key] === null) {
        htmlElements.push(
          <React.Fragment key={newKey}>
            <dt className="col-sm-3 text-break p-2">{newKey}:</dt>
            <dd className="col-sm-9 text-break p-2">{data[key]?.toString()}</dd>
          </React.Fragment>
        );
      }
    }

    for (let key in data) {
      let newKey = parentKey ? `${parentKey}.${key}` : key;
      if (typeof data[key] === "object" && data[key] !== null) {
        if (isRoot) {
          htmlElements.push(
            <p className="separator" key={`p-${newKey}`}>
              {newKey}
            </p>
          );
        }
        htmlElements = [
          ...htmlElements,
          ...flattenJSONtoHTML(data[key], newKey, false),
        ];
      }
    }

    return htmlElements;
  }
  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
      timeZone: "Europe/Berlin",
    });
  };
  const handleSelectChange = (event) => {
    props.setAlertLevel(event.target.value);
  };

  const handleAgentClick = (agentName) => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      agent_name: agentName,
    }));
  };

  return (
    <div className=" mb-3">
      <div>
        <div className="row mb-4 align-middle fw-bold small">
          <span className="flex-grow-1 col-1">LATEST EVENTS</span>

          <div className="col-auto align-middle">
            <Link
              to="/realtime"
              className="text-inverse text-opacity-50 text-decoration-none"
            >
              <i className="bi bi-fullscreen"></i>
            </Link>
          </div>
        </div>

        <div className="table-responsive">
          <div className="row mb-2">
            <div className="col-xl-2 col-lg-2 col-md-2 text-center">
              <DatePicker date={props.setDate} datat={props.datat} />
            </div>
            <div className="col-5 mb-3 text-center"></div>
            <div
              className="col-xl-3 col-lg-3 col-md-2 mb-3 text-center"
              style={{ display: "flex" }}
            >
              <input
                type="text"
                className="form-control py-1 mb-2"
                id="ipaddress"
                value={props.ipAddressFilter || ""}
                placeholder="ip address (i.e. 192.168.1.1)"
                onChange={(e) =>
                  props.setIpAddressFilter((prevFilter) => ({
                    ...prevFilter,
                    attacker_ip: e.target.value,
                  }))
                }
                style={{ flex: "1", paddingRight: "24px" }}
              />
              {props.ipAddressFilter && (
                <button
                  type="button"
                  className="btn  btn-sm mb-1  py-1"
                  onClick={() =>
                    props.setIpAddressFilter((prevFilter) => {
                      const newFilter = { ...prevFilter };
                      delete newFilter.attacker_ip;
                      return newFilter;
                    })
                  }
                  style={{ marginLeft: "-30px", position: "relative" }}
                >
                  X
                </button>
              )}
            </div>

            <div className="col-xl-2 col-lg-2 col-md-12 mb-3 text-center">
              <select
                className="form-select py-1"
                value={props.alertLevel ? props.alertLevel : "0"}
                onChange={handleSelectChange}
              >
                <option value="0">All alerts</option>
                <option value="1">Security Logs</option>
                <option value="13">Critical Alerts</option>
              </select>
            </div>
          </div>
          <table className="table table-striped table-borderless mb-2px small text-nowrap">
            <tbody>
              {currentLogs && currentLogs.length > 0 ? (
                currentLogs.map((log, index) => (
                  <tr key={log.id}>
                    <td>
                      <a
                        href="#/"
                        className="text-decoration-none text-inverse"
                      >
                        {selectedLogIndex === log.id && (
                          <RightSidebar
                            onToggle={() => handleToggleSidebar(log.id)}
                            isOpen={true}
                            agent={log.agent.name}
                          >
                            <div>
                              {" "}
                              <p>
                                <small>
                                  <p>
                                    <dl className="row">
                                      {flattenJSONtoHTML(log)}
                                    </dl>
                                  </p>
                                </small>
                              </p>
                            </div>
                          </RightSidebar>
                        )}

                        {selectedLogIndex !== log.id && (
                          <RightSidebar
                            onToggle={() => handleToggleSidebar(log.id)}
                            isOpen={false}
                            opacity={
                              log.id === selectedLogIndex ? "" : "opacity-25"
                            }
                          >
                            <p>
                              <small>
                                {log.GeoLocation
                                  ? log.GeoLocation.country_name
                                  : "N/A"}
                              </small>
                            </p>
                          </RightSidebar>
                        )}
                      </a>
                      <Link
                        to={`/agent/${log.agent.id}`}
                        className="text-decoration-none te"
                      >
                        <i className="fab fa-lg fa-fw me-2 fa-wpforms opacity-30 text-white"></i>
                      </Link>
                    </td>
                    <td>
                      <span className="d-flex align-items-center">
                        <i
                          className={
                            "bi bi-circle-fill fs-6px me-2  opacity-" +
                            (log["rule"]["level"] >= 12
                              ? "text-red"
                              : "text-inverse text-opacity-30")
                          }
                        ></i>

                        <a
                          style={{ cursor: "pointer" }}
                          className="text-decoration-none"
                          onClick={() => handleAgentClick(log.agent.name)}
                        >
                          {log.agent.name}
                        </a>
                      </span>
                    </td>
                    <td>
                      <small>{formatTimestamp(log.timestamp)}</small>
                    </td>

                    <td>
                      <small>
                        {log.GeoLocation ? (
                          <a
                            href="#/"
                            className="text-decoration-none"
                            onClick={() =>
                              setFilter((prevFilter) => ({
                                ...prevFilter,
                                country: log.GeoLocation.country_name,
                              }))
                            }
                          >
                            {log.GeoLocation.country_name}
                          </a>
                        ) : (
                          "N/A"
                        )}
                      </small>
                    </td>
                    <td>
                      {log.data && log.data.srcip ? (
                        <a
                          href="#/"
                          className="text-decoration-none"
                          onClick={() =>
                            log.data && log.data.srcip
                              ? props.setIpAddressFilter((prevFilter) => ({
                                  ...prevFilter,
                                  attacker_ip: log.data.srcip,
                                }))
                              : ""
                          }
                        >
                          <small>{log.data.srcip}</small>
                        </a>
                      ) : (
                        ""
                      )}
                    </td>

                    <td>
                      <span
                        className={
                          "badge d-block rounded-0 pt-5px " +
                          (log["rule"]["level"] >= 12
                            ? "bg-red text-white"
                            : "bg-inverse bg-opacity-25")
                        }
                        style={{ minHeight: "18px" }}
                      >
                        {
                          // join array log["rule"]["groups"]
                          log["rule"]["description"].substring(0, 50)
                        }
                      </span>
                    </td>
                    <td>
                      <small>
                        {log.full_log
                          ? log.full_log.slice(0, 300)
                          : log.full_log}
                        ...
                      </small>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="4">No threats found</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {/* Add this after the closing </table> tag */}
      </div>
    </div>
  );
}

export default RealTimeLogsDashboard;

import React, { useState, useEffect } from "react";
import ReactECharts from "echarts-for-react";
import * as echarts from "echarts/core";
import { LineChart } from "echarts/charts";
import Cookie from "js-cookie";

import {
  TitleComponent,
  TooltipComponent,
  GridComponent,
} from "echarts/components";
import { SVGRenderer } from "echarts/renderers";

// Register the required components
echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LineChart,
  SVGRenderer,
]);

function Dashboard30DaysChart() {
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    async function fetchChartData() {
      const token = Cookie.get("token");

      try {
        const response = await fetch(
          "https://app.semon.io:8443/alerts_by_period?days=30",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error(`Error fetching chart data: ${response.statusText}`);
        }

        const data = await response.json();
        setChartData(data);
      } catch (error) {
        console.error("Error fetching chart data:", error);
      }
    }

    fetchChartData();
  }, []);

  // Process the received data for the chart
  const categories = chartData.map((item) => {
    const date = new Date(item.day);
    return `${date.getDate()}/${date.getMonth() + 1}`;
  });
  const seriesData = chartData.map((item) => item.counts.level_13);

  const options = {
    tooltip: {
      trigger: "axis",
      backgroundColor: "rgba(0,0,0, 0.8)",
      borderColor: "rgba(255,255,255, 0.2)",
      borderWidth: 1,
      padding: [5, 10],
      textStyle: {
        color: "#a8b6bc",
      },
    },
    grid: {
      left: "0",
      right: "0",
      top: "0",
      bottom: "0",
      containLabel: true,
    },
    xAxis: {
      type: "category",
      data: categories,
      axisLabel: {
        color: "rgba(30, 204, 51, 0.6)",
      },
      axisLine: {
        lineStyle: {
          color: "rgba(30, 204, 51, 0.4)", // replace with your color
        },
      },
      axisTick: {
        lineStyle: {
          color: "rgba(30, 204, 51, 0.4)", // replace with your color
        },
      },
    },
    yAxis: {
      type: "log",
      show: false,
    },
    series: [
      {
        name: "Attacks",
        type: "line",
        areaStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            { offset: 0, color: "rgba(30, 204, 51, 0.85)" },
            { offset: 1, color: "rgba(106, 121, 133, 0.4)" },
          ]),
        },
        lineStyle: {
          color: "rgba(30, 204, 51, 0.85)",
          shadowColor: "rgba(30, 204, 51, 0.5)",
          shadowBlur: 10,
          shadowOffsetY: 10,
        },
        data: seriesData,
        itemStyle: {
          color: "rgba(30, 204, 51, 0.85)",
        },
      },
    ],
  };

  return (
    <div style={{ height: "200px", width: "100%" }}>
      <ReactECharts
        echarts={echarts}
        option={options}
        style={{ height: "100%", width: "100%" }}
      />
    </div>
  );
}

export default Dashboard30DaysChart;

import { AppSettings } from "../../config/app-settings";
import React, { useContext, useEffect, useState } from "react";
import { SearchQueryContext } from "../../context/SearchQueryContext";
import RightSidebar from "./RightSidebar";
import DatePicker from "../forms/DatePicker";
import { Link } from "react-router-dom";
import { FilterContext } from "../../context/FilterContext";

function RootCheckMonitoring(props) {
  const propLogs = props.logs;
  const uniqueLogs = propLogs.filter(
    (log, index, self) =>
      index === self.findIndex((t) => t.full_log === log.full_log)
  );
  const [searchQuery] = useContext(SearchQueryContext);
  const [logs, setLogs] = useState(uniqueLogs);
  const [currentPage, setCurrentPage] = useState(1);
  const [logsPerPage, setLogsPerPage] = useState(50000);
  const [filterLevel, setFilterLevel] = useState(13);
  const [datat, setDate] = useState(null);
  const [selectedLogIndex, setSelectedLogIndex] = useState(null);
  const [filter, setFilter] = useContext(FilterContext);

  const handleToggleSidebar = (index) => {
    setSelectedLogIndex(index === selectedLogIndex ? null : index);
    console.log(index);
  };
  useEffect(() => {
    function handlePageChange(page) {
      setCurrentPage(page);
      if (window.innerWidth < 768) {
        window.scrollTo(0, 0);
      }
    }
    const { innerWidth, innerHeight } = window;
    setLogs(uniqueLogs);
  }, [searchQuery, filterLevel, datat, propLogs]);

  const indexOfLastLog = currentPage * logsPerPage;
  const indexOfFirstLog = indexOfLastLog - logsPerPage;
  const currentLogs = logs.slice(indexOfFirstLog, indexOfLastLog);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(logs.length / logsPerPage); i++) {
    pageNumbers.push(i);
  }

  function handlePageChange(page) {
    setCurrentPage(page);
    if (window.innerWidth < 768) {
      window.scrollTo(0, 0);
    }
  }
  function handleLogsPerPageChange(event) {
    setLogsPerPage(parseInt(event.target.value));
    setCurrentPage(1); // Reset the current page when changing the logs per page
  }
  function flattenJSONtoHTML(data, parentKey = "", isRoot = true) {
    let htmlElements = [];
    for (let key in data) {
      let newKey = parentKey ? `${parentKey}.${key}` : key;
      if (typeof data[key] !== "object" || data[key] === null) {
        htmlElements.push(
          <React.Fragment key={newKey}>
            <dt className="col-sm-3 text-break p-2">{newKey}:</dt>
            <dd className="col-sm-9 text-break p-2">{data[key]?.toString()}</dd>
          </React.Fragment>
        );
      }
    }

    for (let key in data) {
      let newKey = parentKey ? `${parentKey}.${key}` : key;
      if (typeof data[key] === "object" && data[key] !== null) {
        if (isRoot) {
          htmlElements.push(
            <p className="separator" key={`p-${newKey}`}>
              {newKey}
            </p>
          );
        }
        htmlElements = [
          ...htmlElements,
          ...flattenJSONtoHTML(data[key], newKey, false),
        ];
      }
    }

    return htmlElements;
  }

  const handleAgentClick = (agentName) => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      agent_name: agentName,
    }));
  };

  return (
    <div className=" mb-3">
      <div>
        <div className="row mb-4 align-middle fw-bold small">
          <span className="flex-grow-1 col-1">LATEST EVENTS</span>

          <div className="col-auto align-middle">
            <Link
              to="/realtime"
              className="text-inverse text-opacity-50 text-decoration-none"
            >
              <i className="bi bi-fullscreen"></i>
            </Link>
          </div>
        </div>

        <div className="table-responsive">
          <table className="table table-striped table-borderless mb-2px small text-nowrap">
            <tbody>
              {currentLogs && currentLogs.length > 0 ? (
                currentLogs.map((log, index) => (
                  <tr key={log.id}>
                    <td>
                      <a
                        href="#/"
                        className="text-decoration-none text-inverse"
                      >
                        {selectedLogIndex === log.id && (
                          <RightSidebar
                            onToggle={() => handleToggleSidebar(log.id)}
                            isOpen={true}
                            agent={log.agent.name}
                          >
                            <div>
                              {" "}
                              <p>
                                <small>
                                  <p>
                                    <dl className="row">
                                      {flattenJSONtoHTML(log)}
                                    </dl>
                                  </p>
                                </small>
                              </p>
                            </div>
                          </RightSidebar>
                        )}

                        {selectedLogIndex !== log.id && (
                          <RightSidebar
                            onToggle={() => handleToggleSidebar(log.id)}
                            isOpen={false}
                            opacity={
                              log.id === selectedLogIndex ? "" : "opacity-25"
                            }
                          >
                            <p>
                              <small>
                                {log.GeoLocation
                                  ? log.GeoLocation.country_name
                                  : "N/A"}
                              </small>
                            </p>
                          </RightSidebar>
                        )}
                      </a>
                    </td>
                    <td>
                      <span className="d-flex align-items-center">
                        <i
                          className={"bi bi-circle-fill fs-6px me-2  text-red"}
                        ></i>
                        <a
                          style={{ cursor: "pointer" }}
                          className="text-decoration-none"
                          onClick={() => handleAgentClick(log.agent.name)}
                        >
                          {log.agent.name}
                        </a>
                      </span>
                    </td>

                    <td>
                      <span>{log.full_log}</span>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="4">No threats found</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {/* Add this after the closing </table> tag */}
      </div>
    </div>
  );
}

export default RootCheckMonitoring;

import React, { useEffect, useState, useRef, useContext } from "react";
import ReactECharts from "echarts-for-react";
import * as echarts from "echarts/core";
import { MitreIdsContext } from "../../context/MitreIdsContext";
import { FilterContext } from "../../context/FilterContext";

import { use } from "echarts";

function TopAttackGroupsChart({ top_attack_techniques }) {
  const [data, setData] = useState([]);
  const echartRef = useRef(null);
  const [mitreIds, setMitreIds] = useContext(MitreIdsContext);
  const [filter, setFilter] = useContext(FilterContext);

  useEffect(() => {
    if (top_attack_techniques) {
      const processedData = top_attack_techniques
        .map(({ name, doc_count, mitre_ids }) => ({
          name: name.slice(0, 15), // Store the truncated name
          fullName: name, // Store the full name separately
          value: Math.log(doc_count),
          doc_count: doc_count,
          mitre_ids: mitre_ids,
        }))
        .sort((a, b) => a.value - b.value);

      setData(processedData);
    }
  }, [top_attack_techniques]);

  useEffect(() => {
    if (echartRef.current) {
      const instance = echartRef.current.getEchartsInstance();
      const clickHandler = function (params) {
        const item = data.find((d) => d.name === params.name);
        if (item) {
          // Preserve other elements of filter context and only update mitreIds
          setFilter((prevState) => ({
            ...prevState,
            mitreIds: item.mitre_ids,
          }));
        } else {
          console.log("Clicked bar name not found in data:", params.name);
        }
      };
      instance.on("click", clickHandler);

      // cleanup function
      return () => {
        instance.off("click", clickHandler);
      };
    }
  }, [echartRef, data]);

  const option = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
      backgroundColor: "rgba(0,0,0, 0.8)",
      borderColor: "rgba(255,255,255, 0.2)",
      borderWidth: 1,
      padding: [5, 10],
      textStyle: {
        color: "#a8b6bc",
      },
      extraCssText: "box-shadow: 0 0 10px rgba(255, 255, 255, 0.1);",
      formatter: function (params) {
        const item = data[params[0].dataIndex];
        var tooltipText = item.fullName + "<br/>";
        tooltipText +=
          "Events: " + Math.exp(params[0].value).toFixed(0) + "<br/>";
        return tooltipText;
      },
    },
    grid: {
      top: "10px",
      left: "30px",
      right: "20px",
      bottom: "0px",
      containLabel: true,
    },
    xAxis: {
      type: "value",
      show: false,
      axisTick: {
        show: false,
      },
    },
    yAxis: {
      type: "category",
      data: data.map((item) => item.name), // Now use the truncated name
      axisLabel: {
        fontSize: 10,
        color: "rgba(30, 204, 51, 0.6)",
      },
    },
    series: [
      {
        name: "Top Attack Groups",
        type: "bar",
        barWidth: "50%", // adjust this value
        barCategoryGap: "20%",
        data: data.map((item) => ({
          value: item.value,
          doc_count: item.doc_count,
          mitre_ids: item.mitre_ids,
        })),
        itemStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            { offset: 0, color: "rgba(30, 204, 51, 0.85)" },
            { offset: 1, color: "rgba(106, 121, 133, 0.4)" },
          ]),
          borderWidth: 0.9,
          borderColor: "rgba(30, 204, 51, 0.6)",
          shadowColor: "rgba(30, 204, 51, 0.4)",
          shadowBlur: 10,
          shadowOffsetY: 4,
        },
      },
    ],
  };

  return (
    <ReactECharts
      ref={echartRef}
      option={option}
      style={{ height: "100%", width: "100%", minHeight: "200px" }}
    />
  );
}

export default TopAttackGroupsChart;

import RealTimeLogs from "../../components/activity/RealTimeLogs";
import {
  Card,
  CardBody,
  CardExpandToggler,
} from "../../components/card/card.jsx";

function RelTime() {
  return (
    <div>
      <div>
        <Card className="mb-3 ">
          <CardBody>
            <RealTimeLogs />
          </CardBody>
        </Card>
      </div>
    </div>
  );
}

export default RelTime;

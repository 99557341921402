import { AppSettings } from "../../config/app-settings";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Cookie from "js-cookie";
import { SearchQueryContext } from "../../context/SearchQueryContext";
import RealTimeLogsDashboard from "./RealTimeLogsDashboard";
import { MitreIdsContext } from "../../context/MitreIdsContext";
import { FilterContext } from "../../context/FilterContext";

function RealTimeLogs(props) {
  const context = useContext(AppSettings);
  const [searchQuery] = useContext(SearchQueryContext);
  const [loading, setLoading] = useState(true);
  const [logs, setLogs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [logsPerPage, setLogsPerPage] = useState(20);
  const [filterLevel, setFilterLevel] = useState(13);
  const [datat, setDate] = useState(null);
  const { id: agentId } = useParams();
  const [selectedLogIndex, setSelectedLogIndex] = useState(null);
  const [group, setGroup] = useState(null);
  const [agents, setAgents] = useState([]);
  const [ipAddressFilter, setIpAddressFilter] = useState(null);
  const [alertLevel, setAlertLevel] = useState(0);
  const [mitreIds, setMitreIds] = useContext(MitreIdsContext);
  const [filter, setFilter] = useContext(FilterContext);

  const handleLogClick = (index) => {
    setSelectedLogIndex(index === selectedLogIndex ? null : index);
  };

  const handleToggleSidebar = (index) => {
    setSelectedLogIndex(index === selectedLogIndex ? null : index);
  };
  useEffect(() => {
    async function fetchLogs() {
      const token = Cookie.get("token");
      // Pagination parameters
      const offsetParam = `offset=${logsPerPage * (currentPage - 1)}`;
      const limitParam = `limit=${logsPerPage}`;

      // Other parameters
      const startDateParam =
        datat && datat.startDate
          ? `&from_date=${datat.startDate.format("YYYY-MM-DD")}`
          : "";
      const endDateParam =
        datat && datat.endDate
          ? `&to_date=${datat.endDate.format("YYYY-MM-DD")}`
          : "";
      const agentIdParam = agentId ? `&agent_id=${agentId}` : "";
      if (group) {
        const groupParam = `&rule_groups=${group}`;
      }
      const groupParam = group ? `&rule_groups=${group}` : "";
      const ipAddressFilterData = ipAddressFilter
        ? `&ip_address=${ipAddressFilter}`
        : "";
      const alertLevelData = alertLevel ? `&alert_level=${alertLevel}` : "";
      const mitreIdsData =
        filter.mitre_ids && Object.keys(filter).length > 0
          ? `&mitre_ids=${filter.mitreIds.join(",")}`
          : "";

      const { agent_name, attacker_ip, country } = filter;
      const agentNameParam = agent_name ? `&agent_name=${agent_name}` : "";
      const attackerIpParam = attacker_ip ? `&attacker_ip=${attacker_ip}` : "";
      const countryParam = country ? `&country=${country}` : "";
      try {
        const response = await fetch(
          `https://app.semon.io:8443/alerts?${offsetParam}&${limitParam}${agentIdParam}${startDateParam}${endDateParam}${groupParam}${ipAddressFilterData}${alertLevelData}${mitreIdsData}${agentNameParam}${attackerIpParam}${countryParam}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error(`Error fetching logs list: ${response.statusText}`);
        }

        const data = await response.json();
        if (data) {
          const filteredData = data.filter((log) =>
            log.agent.name.toLowerCase().includes(searchQuery.toLowerCase())
          );
          setLogs(filteredData);
          setLoading(false);
          // console.log(filteredData);
        } else {
          setLogs([]);
        }
      } catch (error) {
        console.error("Error fetching agents list:", error);
        setLogs([]);
      } finally {
        // console.log("finally");
        setLoading(false);
      }
    }
    fetchLogs();
    let intervalId = setInterval(fetchLogs, 3000);
    return function cleanUp() {
      context.setAppHeaderNone(false);
      context.setAppSidebarNone(false);
      context.setAppContentClass("");
      clearInterval(intervalId);
      // setDate(null);
    };
  }, [
    agents[0],
    currentPage,
    logsPerPage,
    group,
    searchQuery,
    datat,
    ipAddressFilter,
    alertLevel,
    mitreIds,
    filter,
  ]);

  const indexOfLastLog = currentPage * logsPerPage;
  const indexOfFirstLog = indexOfLastLog - logsPerPage;
  const currentLogs = logs.slice(indexOfFirstLog, indexOfLastLog);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(logs.length / logsPerPage); i++) {
    pageNumbers.push(i);
  }

  function handlePageChange(page) {
    setCurrentPage(page);
    if (window.innerWidth < 768) {
      window.scrollTo(0, 0);
    }
  }
  function handleLogsPerPageChange(event) {
    setLogsPerPage(parseInt(event.target.value));
    setCurrentPage(1); // Reset the current page when changing the logs per page
  }
  return (
    <div>
      {loading ? ( // display loading spinner while loading is true
        <div className="loading-container">
          <div className="loading-bar" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <div>
          <RealTimeLogsDashboard
            logs={logs}
            loading={loading}
            setDate={setDate}
            datat={datat}
            ipAddressFilter={filter.attacker_ip}
            setIpAddressFilter={setFilter}
            alertLevel={alertLevel}
            setAlertLevel={setAlertLevel}
          />
          <div className="row">
            <div className="col-xl-12 col-lg-12 mt-4">
              {/* Tab navs... */}
              {/* Pagination... */}
              <div className="d-flex justify-content-between align-items-center mb-3">
                <ul
                  className="pagination d-flex align-items-center"
                  style={{ width: "100%" }}
                >
                  <li className="me-auto" style={{ padding: "4px" }}>
                    <select
                      id="logsPerPage"
                      className="form-select"
                      value={logsPerPage}
                      onChange={(e) => setLogsPerPage(Number(e.target.value))}
                    >
                      <option value={10}>10</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                      <option value={500}>500</option>
                    </select>
                  </li>
                  <li
                    className={`page-item ${
                      currentPage === 1 ? "disabled" : ""
                    }`}
                    style={{ padding: "4px" }}
                  >
                    <a
                      className="page-link"
                      onClick={() =>
                        setCurrentPage(currentPage > 1 ? currentPage - 1 : 1)
                      }
                      style={{ cursor: "pointer" }}
                    >
                      Previous
                    </a>
                  </li>
                  <li
                    style={{ padding: "4px" }}
                    className="d-flex align-items-center px-4"
                  >
                    <span>Page {currentPage}</span>
                  </li>
                  <li className={`page-item `} style={{ padding: "4px" }}>
                    <a
                      className="page-link"
                      onClick={() => setCurrentPage(currentPage + 1)}
                      style={{ cursor: "pointer" }}
                    >
                      Next
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default RealTimeLogs;

import RealTimeLogsDashboard from "../../components/activity/RealTimeLogsDashboard";
import AttackMap from "../../components/agent/AttackMap";
import Dashboard30DaysChart from "../../components/charts/Dashboard30DaysChart";
import Dashboard24HoursChart from "../../components/charts/Dashboard24HoursChart";
import { Card, CardBody } from "./../../components/card/card.jsx";
import Cookie from "js-cookie";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { SearchQueryContext } from "../../context/SearchQueryContext";
import { AppSettings } from "../../config/app-settings";
import ComplianceDashboardChart from "../../components/charts/ComplianceDashboardChart";
import TopAttackGroupsChart from "../../components/charts/TopAttackGroupsChart";
import TopAttackCountriesChart from "../../components/charts/TopAttackGroupsCountries";
import TopAttackedAgents from "../../components/charts/TopAttackedAgents";
import TopAttackingIPs from "../../components/charts/TopAttackingIPs";
import { FilterContext } from "../../context/FilterContext";
import DashboardStats from "../../components/charts/DashboardStats";

function Home() {
  const [datat, setDate] = useState(null);
  const [dashboardData, setDashboardData] = useState([]);
  const context = useContext(AppSettings);
  const [searchQuery] = useContext(SearchQueryContext);
  const [filter, setFilter] = useContext(FilterContext);
  const mitreIds = filter.mitreIds;

  const [logs, setLogs] = useState([]);
  const [filterLevel, setFilterLevel] = useState(13);
  const [loading, setLoading] = useState(true);
  const { id: agentId } = useParams();
  const [ipAddressFilter, setIpAddressFilter] = useState(null);
  const [alertLevel, setAlertLevel] = useState(0);
  useEffect(() => {
    async function fetchLogs() {
      const token = Cookie.get("token");
      // Check if the start and end dates are available
      // Check if the start and end dates are available
      const startDateParam =
        datat && datat.startDate
          ? `&from_date=${datat.startDate.format("YYYY-MM-DD")}`
          : "";
      const endDateParam =
        datat && datat.endDate
          ? `&to_date=${datat.endDate.format("YYYY-MM-DD")}`
          : "";
      const agentIdParam = agentId ? `&agent_id=${agentId}` : "";
      const ipAddressFilterData = ipAddressFilter
        ? `&ip_address=${ipAddressFilter}`
        : "";

      const alertLevelData = alertLevel ? `&alert_level=${alertLevel}` : "";
      const mitreIdsData = filter.mitreIds
        ? `&mitre_ids=${filter.mitreIds.join(",")}`
        : "";

      const { agent_name, attacker_ip, country } = filter;
      const agentNameParam = agent_name ? `&agent_name=${agent_name}` : "";
      const attackerIpParam = attacker_ip ? `&attacker_ip=${attacker_ip}` : "";
      const countryParam = country ? `&country=${country}` : "";

      try {
        const response = await fetch(
          `https://app.semon.io:8443/alerts?limit=15&num_events=20${agentIdParam}${startDateParam}${endDateParam}${ipAddressFilterData}${alertLevelData}${mitreIdsData}${agentNameParam}${attackerIpParam}${countryParam}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error(`Error fetching logs list: ${response.statusText}`);
        }

        const data = await response.json();
        if (data) {
          const filteredData = data.filter((log) =>
            log.agent.name.toLowerCase().includes(searchQuery.toLowerCase())
          );
          setLogs(filteredData);
        } else {
          setLogs([]);
        }
      } catch (error) {
        console.error("Error fetching agents list:", error);
        setLogs([]);
      } finally {
        // console.log("finally");
      }
    }

    async function fetchDashboardData() {
      const token = Cookie.get("token");

      try {
        const response = await fetch(
          `https://app.semon.io:8443/get_dashboard_data`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error(`Error fetching logs list: ${response.statusText}`);
        }

        const data = await response.json();

        if (data) {
          setDashboardData(data.data);
          setLoading(false);
        } else {
          setDashboardData([]);
        }
      } catch (error) {
        console.error("Error fetching agents list:", error);
        setDashboardData([]);
      } finally {
        // console.log(dashboardData);
      }
    }

    fetchLogs();
    fetchDashboardData();
    let intervalId = setInterval(fetchLogs, 3000);
    let intervalId2 = setInterval(fetchDashboardData, 6000);
    return function cleanUp() {
      context.setAppHeaderNone(false);
      context.setAppSidebarNone(false);
      context.setAppContentClass("");
      clearInterval(intervalId);
      // setDate(null);
    };
  }, [searchQuery, filterLevel, datat, ipAddressFilter, alertLevel, filter]);

  return (
    <div>
      {loading ? ( // display loading spinner while loading is true
        <div className="loading-container">
          <div className="loading-bar" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <div>
          <div className="row ">
            <div className="col-xl-3 col-lg-3  mb-4">
              <Card style={{ height: "100%" }}>
                <CardBody>
                  <small className="row justify-content-center opacity-5">
                    Events past 24 hours
                  </small>
                  <Dashboard24HoursChart />
                </CardBody>
              </Card>
            </div>
            <div className="col-xl-3 col-lg-3  mb-4">
              <Card className="align-items-stretch" style={{ height: "100%" }}>
                <CardBody className="align-items-stretch">
                  <small className="row justify-content-center opacity-5">
                    Attacks past 30 days
                  </small>
                  <Dashboard30DaysChart />
                </CardBody>
              </Card>
            </div>
            <div className="col-xl-3 col-lg-3  mb-4">
              <Card style={{ height: "100%" }}>
                <CardBody>
                  <small className="row justify-content-center opacity-5">
                    TOP Attackin techniques
                  </small>
                  <TopAttackGroupsChart
                    top_attack_techniques={dashboardData.top_attack_techniques}
                  />
                </CardBody>
              </Card>
            </div>
            <div className="col-xl-3 col-lg-3  mb-4">
              <Card style={{ height: "100%" }}>
                <CardBody>
                  {/* <small className="row justify-content-center opacity-5">
                    Events by Compliance Standard
                  </small> */}
                  <DashboardStats dashboardData={dashboardData} />
                </CardBody>
              </Card>
            </div>
          </div>
          <div className="row" style={{ height: "100%" }}>
            <div
              className="col-xl-6 col-lg-6  mb-4 "
              style={{ height: "100%" }}
            >
              <Card>
                <CardBody>
                  <RealTimeLogsDashboard
                    logs={logs}
                    loading={loading}
                    setDate={setDate}
                    datat={datat}
                    ipAddressFilter={filter.attacker_ip}
                    setIpAddressFilter={setFilter}
                    alertLevel={alertLevel}
                    setAlertLevel={setAlertLevel}
                  />
                </CardBody>
              </Card>
            </div>
            <div className="col-xl-6 col-lg-6 mb-4 d-none d-lg-block">
              <div className="row">
                <div className="col-xl-6 col-lg-6 mb-4">
                  <Card style={{ height: "100%" }}>
                    <CardBody>
                      <AttackMap />
                    </CardBody>
                  </Card>
                </div>
                <div className="col-xl-6 col-lg-6 mb-4">
                  <Card style={{ height: "100%" }}>
                    <CardBody>
                      <small className="row justify-content-center opacity-5">
                        TOP Attacking Countries
                      </small>
                      <TopAttackCountriesChart
                        top_attacking_countries={
                          dashboardData.top_attacking_countries
                        }
                      />
                    </CardBody>
                  </Card>
                </div>
                <div className="col-xl-6 col-lg-6 mb-4">
                  <Card>
                    <CardBody>
                      <TopAttackedAgents
                        top_attacked_agents={dashboardData.top_attacked_agents}
                      />
                    </CardBody>
                  </Card>
                </div>
                <div className="col-xl-6 col-lg-6 mb-4">
                  <div className="row">
                    <div className="col-xl-12 col-lg-12 mb-4">
                      <Card>
                        <CardBody>
                          <TopAttackingIPs
                            ipsData={dashboardData.top_ips_24h}
                          />
                        </CardBody>
                      </Card>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Home;

import React, { useState } from "react";
import Moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";

function DatePicker(props) {
  const [dateRangeCurrentWeek, setDateRangeCurrentWeek] = useState(
    Moment().subtract(7, "days").format("D MMM YYYY") +
      " - " +
      Moment().format("D MMM YYYY")
  );
  const [dateRangePrevWeek, setDateRangePrevWeek] = useState(
    Moment().subtract(15, "days").format("D MMM") +
      " - " +
      Moment().subtract(8, "days").format("D MMM YYYY")
  );
  const [startDate, setStartDate] = useState(Moment().subtract(7, "days"));
  const [endDate, setEndDate] = useState(Moment());

  function handleDateApplyEvent(event, picker) {
    setStartDate(picker.startDate);
    setEndDate(picker.endDate);
    var gap = endDate.diff(startDate, "days");
    props.date({ startDate: picker.startDate, endDate: picker.endDate });

    var currentWeek =
      startDate.format("D MMM YYYY") + " - " + endDate.format("D MMM YYYY");
    var prevWeek =
      Moment(startDate).subtract(gap, "days").format("D MMM") +
      " - " +
      Moment(startDate).subtract(1, "days").format("D MMM YYYY");

    setDateRangeCurrentWeek(currentWeek);
    setDateRangePrevWeek(prevWeek);
  }
  return (
    <div className="d-sm-flex align-items-center mb-3 ">
      <DateRangePicker
        startDate={startDate}
        endDate={endDate}
        onApply={handleDateApplyEvent}
        defaultValue={"2017-05-24T10:30:14"}
      >
        <button className="btn btn-outline-secondary text-truncate me-1 mb-2 mb-sm-0 btn-sm">
          <i className="fa fa-fw fa-calendar me-1"></i>
          <span>{dateRangeCurrentWeek}</span>
          <i className="fa fa-fw fa-caret-down me-n1"></i>
        </button>
      </DateRangePicker>
    </div>
  );
}

export default DatePicker;

import React from "react";
import App from "./../app.jsx";
import { Navigate } from "react-router-dom";

import Home from "./../pages/home/home.js";
import Login from "../pages/login/login.js";
import Agents from "../pages/agents/agents.js";
import AgentDetail from "../pages/agents/agentDetail.js";
import LiveMap from "../pages/map/liveMap.js";
import Realtime from "../pages/realtime/realtime.js";
import AwsDetails from "../pages/aws/awsDetails.js";
import Office365Details from "../pages/microsoft/office365Details.js";
import GithubDetails from "../pages/github/githubDetails.js";

const AppRoute = [
  {
    path: "*",
    element: <App />,
    children: [
      { path: "", element: <Navigate to="/home" /> },
      { path: "home", element: <Home /> },
      { path: "login", element: <Login /> },
      { path: "agents", element: <Agents /> },
      { path: "live", element: <LiveMap /> },
      { path: "realtime", element: <Realtime /> },
      { path: "aws", element: <AwsDetails /> },
      { path: "office365", element: <Office365Details /> },
      { path: "github", element: <GithubDetails /> },

      {
        path: "agent/*",
        children: [{ path: ":id", element: <AgentDetail /> }],
      },
    ],
  },
];

export default AppRoute;

import React, { useContext, useEffect, useState } from "react";
import { useTable } from "react-table";
import { FilterContext } from "../../context/FilterContext";

function TopAttackedAgents({ top_attacked_agents }) {
  const [data, setData] = useState([]);
  const [filter, setFilter] = useContext(FilterContext);

  useEffect(() => {
    if (top_attacked_agents) {
      const processedData = Object.entries(top_attacked_agents)
        .map(([key, value]) => ({
          agent_name: key,
          events: value,
        }))
        .sort((b, a) => a.events - b.events); // Sorting in ascending order

      setData(processedData);
      // console.log(processedData);
    }
  }, [top_attacked_agents]);

  const columns = React.useMemo(
    () => [
      {
        Header: "TOP Targeted Agents",
        accessor: "agent_name",
      },
      {
        Header: "Count",
        accessor: "events",
      },
    ],
    []
  );

  const handleAgentClick = (agentName) => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      agent_name: agentName,
    }));
  };

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });

  return (
    <table
      {...getTableProps()}
      style={{ width: "100%", margin: "0 auto" }}
      className="table table-striped table-borderless mb-2px small text-nowrap"
    >
      <thead className="opacity-5">
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps()}>{column.render("Header")}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                if (cell.column.id === "agent_name") {
                  return (
                    <td {...cell.getCellProps()}>
                      <span
                        onClick={() =>
                          handleAgentClick(row.original.agent_name)
                        }
                        style={{ cursor: "pointer" }}
                      >
                        {cell.render("Cell")}
                      </span>
                    </td>
                  );
                } else {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                }
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default TopAttackedAgents;

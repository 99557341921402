import React, { useEffect, useContext, useState } from "react";
import { Link, Navigate } from "react-router-dom";
import { AppSettings } from "./../../config/app-settings.js";
import Cookie from "js-cookie";
function PagesLogin() {
  const context = useContext(AppSettings);
  const [redirect, setRedirect] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [alert, setAlert] = useState("");
  const [message, setMessage] = useState(
    "For your protection, please verify your identity1."
  );

  useEffect(() => {
    context.setAppHeaderNone(true);
    context.setAppSidebarNone(true);
    context.setAppContentClass("p-0");

    return function cleanUp() {
      context.setAppHeaderNone(false);
      context.setAppSidebarNone(false);
      context.setAppContentClass("");
    };

    // eslint-disable-next-line
  }, []);

  async function handleSubmit(event) {
    event.preventDefault();

    try {
      const response = await fetch(
        "https://app.semon.io:2053/security/user/authenticate",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Basic ${btoa(`${username}:${password}`)}`,
          },
        }
      );
      if (response.ok) {
        const data = await response.json();
        const token = data.data.token;

        // Set the token as a cookie
        Cookie.set("token", token, { expires: 7 }); // The token will expire in 7 days

        setMessage("Authentication successful!");

        setRedirect(true);
      } else {
        setMessage("Authentication failed. Please check your credentials.");
      }
    } catch (error) {
      setMessage("Authentication failed. Please check your credentials.");
      setAlert("alert alert-danger");
    }
  }

  if (redirect) {
    return <Navigate to="/" />;
  }
  return (
    <div className="login">
      <div className="login-content">
        <form onSubmit={handleSubmit}>
          <h1 className="text-center">Sign In</h1>
          <div className="text-inverse text-opacity-50 text-center mb-4">
            <div className={alert} role="alert">
              {message}
            </div>
          </div>
          <div className="mb-3">
            <label className="form-label">
              Email Address <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className="form-control form-control-lg bg-white bg-opacity-5"
              placeholder=""
              id="username"
              name="username"
              onChange={(event) => setUsername(event.target.value)}
            />
          </div>
          <div className="mb-3">
            <div className="d-flex">
              <label className="form-label">
                Password <span className="text-danger">*</span>
              </label>
              <a
                href="#/"
                className="ms-auto text-inverse text-decoration-none text-opacity-50"
              >
                Forgot password?
              </a>
            </div>
            <input
              id="password"
              name="password"
              type="password"
              className="form-control form-control-lg bg-white bg-opacity-5"
              placeholder=""
              onChange={(event) => setPassword(event.target.value)}
            />
          </div>
          <div className="mb-3">
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                id="customCheck1"
              />
              <label className="form-check-label" htmlFor="customCheck1">
                Remember me
              </label>
            </div>
          </div>
          <button
            type="submit"
            className="btn btn-outline-theme btn-lg d-block w-100 fw-500 mb-3"
          >
            Sign In
          </button>
          <div className="text-center text-inverse text-opacity-50">
            Don't have an account yet? <Link to="/pages/register">Sign up</Link>
            .
          </div>
        </form>
      </div>
    </div>
  );
}

export default PagesLogin;

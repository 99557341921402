import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { SearchQueryContext } from "../../context/SearchQueryContext";
import { AppSettings } from "../../config/app-settings";
import Cookie from "js-cookie";
import AgentCard from "../../components/agent/AgentCard";
import { Card, CardBody } from "./../../components/card/card.jsx";
import RealTimeLogsDashboard from "../../components/activity/RealTimeLogsDashboard";
import Dashboard24HoursChart from "../../components/charts/Dashboard24HoursChart";
import Dashboard30DaysChart from "../../components/charts/Dashboard30DaysChart";
import ComplianceDashboardChart from "../../components/charts/ComplianceDashboardChart";

function Office365Details({}) {
  const { id: agentId } = useParams();
  const [agents, setAgents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [datat, setDate] = useState(null);
  const context = useContext(AppSettings);
  const [searchQuery] = useContext(SearchQueryContext);
  const [logs, setLogs] = useState([]);
  const [filterLevel, setFilterLevel] = useState(13);
  const [currentPage, setCurrentPage] = useState(1);
  const [logsPerPage, setLogsPerPage] = useState(20);
  const [group, setGroup] = useState("office365");

  const [selectedLogIndex, setSelectedLogIndex] = useState(null);

  useEffect(() => {
    async function fetchLogs() {
      const token = Cookie.get("token");
      // Pagination parameters
      const offsetParam = `offset=${logsPerPage * (currentPage - 1)}`;
      const limitParam = `limit=${logsPerPage}`;

      // Other parameters
      const startDateParam =
        datat && datat.startDate
          ? `&from_date=${datat.startDate.format("YYYY-MM-DD")}`
          : "";
      const endDateParam =
        datat && datat.endDate
          ? `&to_date=${datat.endDate.format("YYYY-MM-DD")}`
          : "";
      const agentIdParam = agentId ? `&agent_id=${agentId}` : "";
      if (group) {
        const groupParam = `&rule_groups=${group}`;
      }
      const groupParam = group ? `&rule_groups=${group}` : "";
      try {
        const response = await fetch(
          `https://app.semon.io:8443/alerts?${offsetParam}&${limitParam}${agentIdParam}${startDateParam}${endDateParam}${groupParam}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error(`Error fetching logs list: ${response.statusText}`);
        }

        const data = await response.json();
        if (data) {
          const filteredData = data.filter((log) =>
            log.agent.name.toLowerCase().includes(searchQuery.toLowerCase())
          );
          setLogs(filteredData);
          setLoading(false);
          // console.log(filteredData);
        } else {
          setLogs([]);
        }
      } catch (error) {
        console.error("Error fetching agents list:", error);
        setLogs([]);
      } finally {
        // console.log("finally");
        setLoading(false);
      }
    }
    fetchLogs();
    let intervalId = setInterval(fetchLogs, 3000);
    return function cleanUp() {
      context.setAppHeaderNone(false);
      context.setAppSidebarNone(false);
      context.setAppContentClass("");
      clearInterval(intervalId);
      // setDate(null);
    };
  }, [agents[0], currentPage, logsPerPage, group, searchQuery, datat]);

  if (loading) {
    return (
      <div className="loading-container">
        <div className="loading-bar" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="row">
        <div className="col-xl-12 col-lg-12">
          <Card>
            <div className="tab-content p-4">
              <div className="tab-pane fade show active" id="threatdetection">
                <RealTimeLogsDashboard
                  logs={logs}
                  loading={loading}
                  setDate={setDate}
                  datat={datat}
                />
              </div>
            </div>
          </Card>
          <div className="row">
            <div className="col-xl-12 col-lg-12 mt-4">
              {/* Tab navs... */}
              {/* Pagination... */}
              <div className="d-flex justify-content-between align-items-center mb-3">
                <ul
                  className="pagination d-flex align-items-center"
                  style={{ width: "100%" }}
                >
                  <li className="me-auto" style={{ padding: "4px" }}>
                    <select
                      id="logsPerPage"
                      className="form-select"
                      value={logsPerPage}
                      onChange={(e) => setLogsPerPage(Number(e.target.value))}
                    >
                      <option value={10}>10</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                      <option value={500}>500</option>
                    </select>
                  </li>
                  <li
                    className={`page-item ${
                      currentPage === 1 ? "disabled" : ""
                    }`}
                    style={{ padding: "4px" }}
                  >
                    <a
                      className="page-link"
                      onClick={() =>
                        setCurrentPage(currentPage > 1 ? currentPage - 1 : 1)
                      }
                      style={{ cursor: "pointer" }}
                    >
                      Previous
                    </a>
                  </li>
                  <li
                    style={{ padding: "4px" }}
                    className="d-flex align-items-center px-4"
                  >
                    <span>Page {currentPage}</span>
                  </li>
                  <li className={`page-item `} style={{ padding: "4px" }}>
                    <a
                      className="page-link"
                      onClick={() => setCurrentPage(currentPage + 1)}
                      style={{ cursor: "pointer" }}
                    >
                      Next
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Office365Details;

import React from "react";
import AttackMap from "../../components/agent/AttackMap";

function LiveMap() {
  return (
    <div>
      <AttackMap />
    </div>
  );
}

export default LiveMap;

import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { SearchQueryContext } from "../../context/SearchQueryContext";
import { MitreIdsContext } from "../../context/MitreIdsContext";
import { FilterContext } from "../../context/FilterContext";
import ClearFiltersButton from "../buttons/ClearFilters";

function Header() {
  const [searchQuery, setSearchQuery] = useContext(SearchQueryContext);
  const [timeoutId, setTimeoutId] = useState(null);
  const [mitreIds, setMitreIds] = useContext(MitreIdsContext);
  const [filter, setFilter] = useContext(FilterContext);

  function handleSearchInputChange(event) {
    clearTimeout(timeoutId);
    const newTimeoutId = setTimeout(() => {
      setSearchQuery(event.target.value);
    }, 500);
    setTimeoutId(newTimeoutId);
  }

  const removeTag = (id) => {
    setFilter((prevFilter) => {
      if (prevFilter.mitreIds) {
        return {
          ...prevFilter,
          mitreIds: prevFilter.mitreIds.filter((mitreId) => mitreId !== id),
        };
      }
      return prevFilter;
    });
  };

  function cleanSearchInputChange(event) {
    setSearchQuery("");
  }

  const notificationData = [
    {
      icon: "fas fa-lg fa-fw me-2 fa-shield-alt text-theme",
      title: "Arbresh",
      time: "SQL INJECTION ATTACK DETECTED",
    },
    {
      icon: "fas fa-lg fa-fw me-2 fa-lock-open text-theme",
      title: "Periskopi",
      time: "BRUTE FORCE ATTACK DETECTED",
    },
    {
      icon: "bi bi-clock text-theme",
      title: "8 hour ago",
      time: "ALL EVENTS THAT HAPPENED IN THE LAST 8 HOUR",
    },
    {
      icon: "bi bi-clock text-theme",
      title: "16 hour ago",
      time: "ALL EVENTS THAT HAPPENED IN THE LAST 16 HOUR",
    },
    {
      icon: "bi bi-clock text-theme",
      title: "24 hour ago",
      time: "ALL EVENTS THAT HAPPENED IN THE LAST 24 HOUR",
    },
  ];

  const toggleAppSidebarDesktop = () => {
    var elm = document.querySelector(".app");
    // elm.classList.toggle("app-sidebar-collapsed");
  };

  const toggleAppSidebarMobile = () => {
    var elm = document.querySelector(".app");
    elm.classList.toggle("app-sidebar-mobile-toggled");
  };

  const toggleAppHeaderSearch = () => {
    var elm = document.querySelector(".app");
    elm.classList.toggle("app-header-menu-search-toggled");
  };

  return (
    <div>
      <div id="header" className="app-header row">
        <div className="brand">
          <Link to="/" className="brand-logo">
            <span className="brand-img">
              <span className="brand-img-text text-theme">S</span>
            </span>
            <span className="brand-text">SEMON.IO</span>
          </Link>
        </div>

        <div className="menu">
          <div className="menu-item dropdown">
            <a href="#/" onClick={toggleAppHeaderSearch} className="menu-link">
              <div className="menu-icon">
                <i className="bi bi-search nav-icon"></i>
              </div>
            </a>
          </div>
          <div className="menu-item dropdown dropdown-mobile-full">
            <a
              href="#/"
              data-bs-toggle="dropdown"
              data-bs-display="static"
              className="menu-link"
            >
              <div className="menu-icon">
                <i className="bi bi-grid-3x3-gap nav-icon"></i>
              </div>
            </a>
            <div className="dropdown-menu fade dropdown-menu-end w-300px text-center p-0 mt-1">
              <div className="row row-grid gx-0">
                <div className="col-4">
                  <Link
                    to="/"
                    className="dropdown-item text-decoration-none p-3 bg-none"
                  >
                    <div className="position-relative">
                      <i className="fas fa-home h2 opacity-5 d-block my-1"></i>
                    </div>
                    <div className="fw-500 fs-10px text-inverse">HOME</div>
                  </Link>
                </div>
                <div className="col-4">
                  <Link
                    to="/realtime"
                    className="dropdown-item text-decoration-none p-3 bg-none"
                  >
                    <div className="position-relative">
                      <i className="bi bi-terminal h2 opacity-5 d-block my-1"></i>
                    </div>
                    <div className="fw-500 fs-10px text-inverse">LIVE DATA</div>
                  </Link>
                </div>
                <div className="col-4">
                  <Link
                    to="/agents"
                    className="dropdown-item text-decoration-none p-3 bg-none"
                  >
                    <div>
                      <i className="fas fa-lg fa-server h2 opacity-5 d-block my-1"></i>
                    </div>
                    <div className="fw-500 fs-10px text-inverse">DEVICES</div>
                  </Link>
                </div>
              </div>
              <div className="row row-grid gx-0">
                <div className="col-4">
                  <Link
                    to="/office365"
                    className="dropdown-item text-decoration-none p-3 bg-none"
                  >
                    <div>
                      <i className="h2 opacity-5 d-block my-1 fa-brands fa-windows"></i>
                    </div>
                    <div className="fw-500 fs-10px text-inverse">
                      OFFICE 365
                    </div>
                  </Link>
                </div>
                <div className="col-4">
                  <Link
                    to="/aws"
                    className="dropdown-item text-decoration-none p-3 bg-none"
                  >
                    <div>
                      <i className="h2 opacity-5 d-block my-1 fa-brands fa-aws"></i>
                    </div>
                    <div className="fw-500 fs-10px text-inverse">AMAZON</div>
                  </Link>
                </div>

                <div className="col-4">
                  <Link
                    to="/github"
                    className="dropdown-item text-decoration-none p-3 bg-none"
                  >
                    <div>
                      <i className="h2 opacity-5 d-block my-1 fa-brands fa-github"></i>
                    </div>
                    <div className="fw-500 fs-10px text-inverse">GITHUB</div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="menu-item dropdown dropdown-mobile-full">
            <a
              href="#/"
              data-bs-toggle="dropdown"
              data-bs-display="static"
              className="menu-link"
            >
              <div className="menu-icon">
                <i className="bi bi-bell nav-icon"></i>
              </div>
              <div className="menu-badge bg-theme"></div>
            </a>
            <div className="dropdown-menu dropdown-menu-end mt-1 w-300px fs-11px pt-1">
              <h6 className="dropdown-header fs-10px mb-1">SELECT PERIOD</h6>
              <div className="dropdown-divider mt-1"></div>
              {notificationData.length > 0 ? (
                notificationData.map((notification, index) => (
                  <a
                    href="#/"
                    className="d-flex align-items-center py-10px dropdown-item text-wrap fw-semibold"
                  >
                    <div className="fs-20px">
                      <i className={notification.icon}></i>
                    </div>
                    <div className="flex-1 flex-wrap ps-3">
                      <div className="mb-1 text-inverse">
                        {notification.title}
                      </div>
                      <div className="small text-inverse text-opacity-50">
                        {notification.time}
                      </div>
                    </div>
                    <div className="ps-2 fs-16px">
                      <i className="bi bi-chevron-right"></i>
                    </div>
                  </a>
                ))
              ) : (
                <div className="dropdown-notification-item p-3 text-center">
                  No record found
                </div>
              )}
              <hr className="mb-0 mt-2" />
              <div className="py-10px mb-n2 text-center">
                <a href="#/" className="text-decoration-none fw-bold">
                  SEE ALL
                </a>
              </div>
            </div>
          </div>
          <div className="menu-item dropdown dropdown-mobile-full">
            <a
              href="#/"
              data-bs-toggle="dropdown"
              data-bs-display="static"
              className="menu-link"
            >
              <div className="menu-img online">
                <div className="d-flex align-items-center justify-content-center w-100 h-100 bg-inverse bg-opacity-25 text-inverse text-opacity-50 rounded-circle overflow-hidden">
                  <i className="bi bi-person-fill fs-32px mb-n3"></i>
                </div>
              </div>
              <div className="menu-text d-sm-block d-none w-170px">
                Hekuran Doli
              </div>
            </a>
            <div className="dropdown-menu dropdown-menu-end me-lg-3 fs-11px mt-1">
              <Link
                to="/profile"
                className="dropdown-item d-flex align-items-center"
              >
                PROFILE{" "}
                <i className="bi bi-person-circle ms-auto text-theme fs-16px my-n1"></i>
              </Link>
              <Link
                to="/email/inbox"
                className="dropdown-item d-flex align-items-center"
              >
                INBOX{" "}
                <i className="bi bi-envelope ms-auto text-theme fs-16px my-n1"></i>
              </Link>
              <Link
                to="/calendar"
                className="dropdown-item d-flex align-items-center"
              >
                CALENDAR{" "}
                <i className="bi bi-calendar ms-auto text-theme fs-16px my-n1"></i>
              </Link>
              <Link
                to="/settings"
                className="dropdown-item d-flex align-items-center"
              >
                SETTINGS{" "}
                <i className="bi bi-gear ms-auto text-theme fs-16px my-n1"></i>
              </Link>
              <div className="dropdown-divider"></div>
              <Link
                to="/login"
                className="dropdown-item d-flex align-items-center"
              >
                LOGOUT{" "}
                <i className="bi bi-toggle-off ms-auto text-theme fs-16px my-n1"></i>
              </Link>
            </div>
          </div>
        </div>
        <form className="menu-search" name="header_search_form" action="#">
          <div className="menu-search-container">
            <div className="menu-search-icon">
              <i className="bi bi-search"></i>
            </div>
            <div className="menu-search-input">
              <input
                type="text"
                id="searchQuery"
                name="searchQuery"
                className="form-control form-control-lg"
                placeholder="Search menu..."
                onChange={handleSearchInputChange}
              />
            </div>
            <div className="menu-search-icon">
              <a href="#/" onClick={toggleAppHeaderSearch}>
                <i className="bi bi-x-lg" onClick={cleanSearchInputChange}></i>
              </a>
            </div>
          </div>
        </form>
      </div>
      {filter && Object.keys(filter).length > 0 && (
        <div className="row pt-3 px-3">
          <div
            className="d-flex flex-wrap align-items-center text-theme fs-16px"
            style={{ wordWrap: "break-word", maxWidth: "100%" }}
          >
            <i className="fas fa-lg fa-fw me-2 fa-filter ms-2 mb-3"></i>
            {filter.mitreIds > 0 && <>Mitre ID: &nbsp;</>}
            {filter.mitreIds &&
              filter.mitreIds.map((id, index) => (
                <span
                  key={index}
                  className="badge bg-theme d-flex align-items-center ms-2 mb-3"
                >
                  {id}
                  <span
                    className="ms-2 cursor-pointer"
                    style={{ cursor: "pointer" }}
                    onClick={() => removeTag(id)}
                  >
                    &#x2715;
                  </span>
                </span>
              ))}
            {Object.keys(filter).map((key, index) => {
              if (key !== "mitreIds") {
                return (
                  <span
                    key={index}
                    className="badge bg-theme d-flex align-items-center ms-2 mb-3"
                  >
                    {filter[key]}
                    <span
                      className="ms-2 cursor-pointer"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        setFilter((prevFilter) => {
                          const newFilter = { ...prevFilter };
                          delete newFilter[key];
                          return newFilter;
                        })
                      }
                    >
                      &#x2715;
                    </span>
                  </span>
                );
              }
            })}
            <span className="d-flex align-items-center ms-2 mb-3">
              <ClearFiltersButton />
            </span>
          </div>
        </div>
      )}
    </div>
  );
}

export default Header;

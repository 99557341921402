import { AppSettings } from "../../config/app-settings";
import React, { useContext, useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardExpandToggler,
  CardHeader,
} from "./../../components/card/card.jsx";
import { Link } from "react-router-dom";
import Cookie from "js-cookie";
import Chart from "react-apexcharts";
import jsVectorMap from "jsvectormap";
import "jsvectormap/dist/maps/world.js";
import "jsvectormap/dist/css/jsvectormap.min.css";
import { SearchQueryContext } from "../../context/SearchQueryContext";
function AgentCard(props) {
  function randomNo() {
    return Math.floor(Math.random() * 60) + 30;
  }

  // server chart
  var serverChartData = [
    {
      name: "MEMORY USAGE",
      data: [
        randomNo(),
        randomNo(),
        randomNo(),
        randomNo(),
        randomNo(),
        randomNo(),
        randomNo(),
        randomNo(),
        randomNo(),
        randomNo(),
        randomNo(),
        randomNo(),
        randomNo(),
        randomNo(),
        randomNo(),
        randomNo(),
        randomNo(),
        randomNo(),
        randomNo(),
        randomNo(),
        randomNo(),
        randomNo(),
        randomNo(),
        randomNo(),
      ],
    },
    {
      name: "CPU USAGE",
      data: [
        randomNo(),
        randomNo(),
        randomNo(),
        randomNo(),
        randomNo(),
        randomNo(),
        randomNo(),
        randomNo(),
        randomNo(),
        randomNo(),
        randomNo(),
        randomNo(),
        randomNo(),
        randomNo(),
        randomNo(),
        randomNo(),
        randomNo(),
        randomNo(),
        randomNo(),
        randomNo(),
        randomNo(),
        randomNo(),
        randomNo(),
        randomNo(),
      ],
    },
  ];

  const [statsData, setStatsData] = useState();
  const [serverData, setServerData] = useState();
  const [countryData, setCountryData] = useState();
  const [sourceData, setSourceData] = useState();
  const [sourceChartData, setSourceChartData] = useState();
  const [productData, setProductData] = useState();
  const [activityLogData, setActivityLogData] = useState();
  const [chartOptions, setChartOptions] = useState(getChartOptions());
  const [serverChartOptions, setServerChartOptions] = useState(
    getServerChartOptions()
  );

  function getChartOptions() {
    var themeColor = getComputedStyle(document.body)
      .getPropertyValue("--bs-theme")
      .trim();
    var themeColorRgb = getComputedStyle(document.body)
      .getPropertyValue("--bs-theme-rgb")
      .trim();

    return {
      bar: {
        colors: [themeColor],
        chart: { sparkline: { enabled: true } },
        tooltip: {
          x: { show: false },
          y: {
            title: {
              formatter: function (seriesName) {
                return "";
              },
            },
          },
        },
      },
      line: {
        colors: [themeColor],
        chart: { sparkline: { enabled: true } },
        stroke: { curve: "straight", width: 2 },
        tooltip: {
          x: { show: false },
          y: {
            title: {
              formatter: function (seriesName) {
                return "";
              },
            },
          },
        },
      },
      pie: {
        colors: [
          "rgba(" + themeColorRgb + ", 1)",
          "rgba(" + themeColorRgb + ", .75)",
          "rgba(" + themeColorRgb + ", .5)",
        ],
        chart: { sparkline: { enabled: true } },
        stroke: { show: false },
        tooltip: {
          x: { show: false },
          y: {
            title: {
              formatter: function (seriesName) {
                return "";
              },
            },
          },
        },
      },
      donut: {
        colors: [
          "rgba(" + themeColorRgb + ", .15)",
          "rgba(" + themeColorRgb + ", .35)",
          "rgba(" + themeColorRgb + ", .55)",
          "rgba(" + themeColorRgb + ", .75)",
          "rgba(" + themeColorRgb + ", .95)",
        ],
        chart: { sparkline: { enabled: true } },
        stroke: { show: false },
        tooltip: {
          x: { show: false },
          y: {
            title: {
              formatter: function (seriesName) {
                return "";
              },
            },
          },
        },
      },
    };
  }

  function getServerChartOptions() {
    var borderColor = getComputedStyle(document.body)
      .getPropertyValue("--bs-border-color")
      .trim();
    var bodyColor = getComputedStyle(document.body)
      .getPropertyValue("--bs-body-color")
      .trim();
    var inverseRgb = getComputedStyle(document.body)
      .getPropertyValue("--bs-inverse-rgb")
      .trim();
    var themeColor = getComputedStyle(document.body)
      .getPropertyValue("--bs-theme")
      .trim();
    var themeFont = getComputedStyle(document.body)
      .getPropertyValue("--bs-body-font-family")
      .trim();

    return {
      chart: { toolbar: { show: false } },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
        },
      },
      dataLabels: { enabled: false },
      grid: { show: true, borderColor: borderColor },
      stroke: { show: false },
      colors: ["rgba(" + inverseRgb + ", .15)", themeColor],
      legend: { fontFamily: themeFont, labels: { colors: bodyColor } },
      xaxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        labels: { show: false },
        axisBorder: {
          show: true,
          color: borderColor,
          height: 1,
          width: "100%",
          offsetX: 0,
          offsetY: -1,
        },
        axisTicks: {
          show: true,
          borderType: "solid",
          color: borderColor,
          height: 6,
          offsetX: 0,
          offsetY: 0,
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: bodyColor,
            fontSize: "12px",
            fontFamily: themeFont,
            fontWeight: 400,
            cssClass: "apexcharts-xaxis-label",
          },
        },
      },
      fill: { opacity: 0.65 },
      tooltip: {
        y: {
          formatter: function (val) {
            return "$ " + val + " thousands";
          },
        },
      },
    };
  }

  function renderMap() {
    var inverse = getComputedStyle(document.body)
      .getPropertyValue("--bs-inverse")
      .trim();
    var themeColor = getComputedStyle(document.body)
      .getPropertyValue("--bs-theme")
      .trim();
    const map = document.getElementById("world-map");
    const mapElm = document.querySelectorAll(".jvm-tooltip");

    if (map) {
      for (let i = 0; i < mapElm.length; i++) {
        mapElm[i].remove();
      }
      map.innerHTML = "";

      new jsVectorMap({
        selector: "#world-map",
        map: "world",
        zoomButtons: true,
        normalizeFunction: "polynomial",
        hoverOpacity: 0.5,
        hoverColor: false,
        zoomOnScroll: false,
        series: { regions: [{ normalizeFunction: "polynomial" }] },
        labels: { markers: { render: (marker) => marker.name } },
        focusOn: { x: 0.5, y: 0.5, scale: 1 },
        markerStyle: {
          initial: { fill: themeColor, stroke: "none", r: 5 },
          hover: { fill: themeColor },
        },
        regionStyle: {
          initial: {
            fill: inverse,
            fillOpacity: 0.35,
            stroke: "none",
            strokeWidth: 0.4,
            strokeOpacity: 1,
          },
          hover: { fillOpacity: 0.5 },
        },
        backgroundColor: "transparent",
      });
    }
  }

  useEffect(() => {
    fetch("/assets/data/dashboard/stats.json")
      .then((res) => res.json())
      .then((result) => {
        setStatsData(result);
      });
    fetch("/assets/data/dashboard/server.json")
      .then((res) => res.json())
      .then((result) => {
        setServerData(result);
      });
    fetch("/assets/data/dashboard/country.json")
      .then((res) => res.json())
      .then((result) => {
        setCountryData(result);
      });
    fetch("/assets/data/dashboard/source.json")
      .then((res) => res.json())
      .then((result) => {
        setSourceData(result);
      });
    fetch("/assets/data/dashboard/source-chart.json")
      .then((res) => res.json())
      .then((result) => {
        setSourceChartData(result);
      });
    fetch("/assets/data/dashboard/product.json")
      .then((res) => res.json())
      .then((result) => {
        setProductData(result);
      });
    fetch("/assets/data/dashboard/activity-log.json")
      .then((res) => res.json())
      .then((result) => {
        setActivityLogData(result);
      });

    renderMap();

    document.addEventListener("theme-reload", () => {
      setServerChartOptions(getServerChartOptions());
      setChartOptions(getChartOptions());
      renderMap();
    });

    // eslint-disable-next-line
  }, []);
  function getChartOptions() {
    var themeColor = getComputedStyle(document.body)
      .getPropertyValue("--bs-theme")
      .trim();
    var themeColorRgb = getComputedStyle(document.body)
      .getPropertyValue("--bs-theme-rgb")
      .trim();

    return {
      bar: {
        colors: [themeColor],
        chart: { sparkline: { enabled: true } },
        tooltip: {
          x: { show: false },
          y: {
            title: {
              formatter: function (seriesName) {
                return "";
              },
            },
          },
        },
      },
    };
  }
  if (props.agent) {
    return (
      <div>
        <Card className="mb-3">
          <CardHeader className="d-flex align-items-center bg-inverse bg-opacity-10">
            <span className="flex-grow-1">
              <Link to={`/agent/${props.agent.id}`}>{props.agent.name}</Link>
            </span>

            {/* console log works, when clicking over CardExpandToggler */}

            <a
              href="#/"
              className="text-inverse text-opacity-50 text-decoration-none me-3"
            >
              <i className="fa fa-fw fa-redo"></i>
            </a>
            <a
              href="#/"
              className="text-inverse text-opacity-50 text-decoration-none"
            >
              <i className="fa fa-fw fa-trash"></i>
            </a>
          </CardHeader>
          <CardBody>
            <div className="row align-items-center mb-2">
              <div className="col-7">
                <small className="small text-inverse text-opacity-30 text-truncate">
                  Security Score (CIS)
                </small>
                <h3 className="mb-0">
                  {props.agent.cisc_score
                    ? props.agent.cisc_score + "%"
                    : "N/A"}
                </h3>
              </div>
              <div className="col-5">
                <div className="mt-n2">
                  {props.agent.status === "disconnected" ? (
                    <div className="badge bg-danger">
                      <i className="far fa-times-circle fa-fw me-1"></i>
                      <small>{props.agent.status}</small>
                    </div>
                  ) : (
                    <Chart
                      type="bar"
                      height="30"
                      options={chartOptions["bar"]}
                      series={[
                        {
                          data: props.agent.alert_count_by_hour.map(
                            (obj) => obj.count
                          ),
                        },
                      ]}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="small text-inverse text-opacity-50 text-truncate">
              <div>
                <i className="fa fa-chevron-up fa-fw me-1"></i>IP Address:{" "}
                {props.agent.ip}
              </div>
              <div>
                <i className="far fa-user fa-fw me-1"></i>
                {props.agent.os.name} - {props.agent.os.version}
              </div>
              <div>
                <i className="far fa-times-circle fa-fw me-1"></i>
                Status: {props.agent.status}
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default AgentCard;

import React, { useContext, useEffect, useState } from "react";
import { useTable } from "react-table";
import { FilterContext } from "../../context/FilterContext";

function TopAttackingIps({ ipsData }) {
  const [data, setData] = useState([]);
  const [filter, setFilter] = useContext(FilterContext);
  // console.log(ipsData);

  useEffect(() => {
    if (ipsData) {
      const processedData = Object.entries(ipsData)
        .map(([key, value]) => ({
          ip: key,
          count: value,
        }))
        .sort((b, a) => a.count - b.count); // Sorting in ascending order

      setData(processedData);
      // console.log(processedData);
    }
  }, [ipsData]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Attacking IP Address",
        accessor: "ip", // changed from "ip"
      },
      {
        Header: "Count",
        accessor: "count", // changed from "count"
      },
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });

  const handleIpClick = (attacker_ip) => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      attacker_ip: attacker_ip,
    }));
  };

  return (
    <table
      {...getTableProps()}
      style={{ width: "100%", margin: "0 auto" }}
      className="table table-striped table-borderless mb-2px small text-nowrap"
    >
      <thead className="opacity-5">
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps()}>{column.render("Header")}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                if (cell.column.id === "ip") {
                  return (
                    <td {...cell.getCellProps()}>
                      <span
                        onClick={() => handleIpClick(row.original.ip)}
                        style={{ cursor: "pointer" }}
                      >
                        {cell.render("Cell")}
                      </span>
                    </td>
                  );
                } else {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                }
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default TopAttackingIps;

import { AppSettings } from "../../config/app-settings";
import React, { useContext, useEffect, useState } from "react";
import { SearchQueryContext } from "../../context/SearchQueryContext";
import RightSidebar from "./RightSidebar";
import DatePicker from "../forms/DatePicker";
import { Link } from "react-router-dom";
import { FilterContext } from "../../context/FilterContext";

function VulnerabilityManagement(props) {
  const l = uniqueByCVE(props.logs);
  const [searchQuery] = useContext(SearchQueryContext);
  const [logs, setLogs] = useState(l);
  const [currentPage, setCurrentPage] = useState(1);
  const [logsPerPage, setLogsPerPage] = useState(50000);
  const [filterLevel, setFilterLevel] = useState(13);
  const [datat, setDate] = useState(null);
  const [selectedLogIndex, setSelectedLogIndex] = useState(null);
  const [filter, setFilter] = useContext(FilterContext);

  const handleLogClick = (index) => {
    setSelectedLogIndex(index === selectedLogIndex ? null : index);
  };

  function uniqueByCVE(logs) {
    const seen = new Set();
    return logs.filter((log) => {
      const cve = log.data?.vulnerability?.cve;
      if (cve && !seen.has(cve)) {
        seen.add(cve);
        return true;
      }
      return false;
    });
  }

  const handleToggleSidebar = (index) => {
    setSelectedLogIndex(index === selectedLogIndex ? null : index);
    console.log(index);
  };
  useEffect(() => {
    function handlePageChange(page) {
      setCurrentPage(page);
      if (window.innerWidth < 768) {
        window.scrollTo(0, 0);
      }
    }
    const { innerWidth, innerHeight } = window;
    setLogs(l);
  }, [searchQuery, filterLevel, datat, l]);

  const indexOfLastLog = currentPage * logsPerPage;
  const indexOfFirstLog = indexOfLastLog - logsPerPage;
  const currentLogs = logs.slice(indexOfFirstLog, indexOfLastLog);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(logs.length / logsPerPage); i++) {
    pageNumbers.push(i);
  }

  function handlePageChange(page) {
    setCurrentPage(page);
    if (window.innerWidth < 768) {
      window.scrollTo(0, 0);
    }
  }
  function handleLogsPerPageChange(event) {
    setLogsPerPage(parseInt(event.target.value));
    setCurrentPage(1); // Reset the current page when changing the logs per page
  }
  function flattenJSONtoHTML(data, parentKey = "", isRoot = true) {
    let htmlElements = [];
    for (let key in data) {
      let newKey = parentKey ? `${parentKey}.${key}` : key;
      if (typeof data[key] !== "object" || data[key] === null) {
        htmlElements.push(
          <React.Fragment key={newKey}>
            <dt className="col-sm-3 text-break p-2">{newKey}:</dt>
            <dd className="col-sm-9 text-break p-2">{data[key]?.toString()}</dd>
          </React.Fragment>
        );
      }
    }

    for (let key in data) {
      let newKey = parentKey ? `${parentKey}.${key}` : key;
      if (typeof data[key] === "object" && data[key] !== null) {
        if (isRoot) {
          htmlElements.push(
            <p className="separator" key={`p-${newKey}`}>
              {newKey}
            </p>
          );
        }
        htmlElements = [
          ...htmlElements,
          ...flattenJSONtoHTML(data[key], newKey, false),
        ];
      }
    }

    return htmlElements;
  }
  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
      timeZone: "Europe/Berlin",
    });
  };
  const handleSelectChange = (event) => {
    props.setAlertLevel(event.target.value);
  };

  const handleAgentClick = (agentName) => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      agent_name: agentName,
    }));
  };

  return (
    <div className=" mb-3">
      <div>
        <div className="row mb-4 align-middle fw-bold small">
          <span className="flex-grow-1 col-1">LATEST EVENTS</span>

          <div className="col-auto align-middle">
            <Link
              to="/realtime"
              className="text-inverse text-opacity-50 text-decoration-none"
            >
              <i className="bi bi-fullscreen"></i>
            </Link>
          </div>
        </div>

        <div className="table-responsive">
          <table className="table table-striped table-borderless mb-2px small text-wrap p-4">
            <tbody>
              {currentLogs && currentLogs.length > 0 ? (
                currentLogs.map(
                  (log, index) =>
                    log.data.vulnerability?.status === "Active" && (
                      <tr key={log.id}>
                        <td>
                          <span className="d-flex align-items-center">
                            {log.data.vulnerability?.cvss?.cvss3?.base_score >
                              6.9 && (
                              <i
                                className="bi bi-circle-fill text-danger me-1"
                                style={{ fontSize: "0.5rem" }}
                              ></i>
                            )}
                          </span>
                        </td>

                        <td>
                          <span className="d-flex align-items-center">
                            <h6>
                              {log.data.vulnerability?.title &&
                                log.data.vulnerability.title}
                            </h6>
                          </span>
                          <span className="d-flex align-items-center">
                            CVE:{" "}
                            {log.data.vulnerability?.cve &&
                              log.data.vulnerability.cve}
                          </span>
                          <span className="d-flex align-items-center">
                            Severity:{" "}
                            {log.data.vulnerability?.severity &&
                              log.data.vulnerability.severity}
                          </span>
                          <span className="d-flex align-items-center">
                            Published on:
                            {log.data.vulnerability?.published &&
                              log.data.vulnerability.published}
                          </span>
                          <strong>Description:</strong>
                          <span className="d-flex align-items-center">
                            {log.data.vulnerability?.rationale &&
                              log.data.vulnerability.rationale}
                          </span>

                          {log.data.vulnerability?.references &&
                            log.data.vulnerability.references.map(
                              (reference, index) => (
                                <p key={index}>
                                  <a
                                    href={reference}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    {reference}
                                  </a>
                                </p>
                              )
                            )}
                        </td>
                      </tr>
                    )
                )
              ) : (
                <tr>
                  <td colSpan="4">No threats found</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {/* Add this after the closing </table> tag */}
      </div>
    </div>
  );
}

export default VulnerabilityManagement;

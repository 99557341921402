import React, { useContext, useEffect, useState } from "react";
import jsVectorMap from "jsvectormap";
import "jsvectormap/dist/maps/world.js";
import "jsvectormap/dist/css/jsvectormap.min.css";
import {
  Card,
  CardBody,
  CardExpandToggler,
} from "./../../components/card/card.jsx";
import Chart from "react-apexcharts";
import Cookies from "js-cookie";
import { AppSettings } from "../../config/app-settings";

function AttackMap() {
  const [countryData, setCountryData] = useState();
  const [sourceData, setSourceData] = useState();
  const [sourceChartData, setSourceChartData] = useState();
  const [chartOptions, setChartOptions] = useState(getChartOptions());
  const [markers, setMarkers] = useState([]);
  const [loading, setLoading] = useState(true);
  const context = useContext(AppSettings);

  useEffect(() => {
    const token = Cookies.get("token");

    fetch("https://app.semon.io:8443/get_top_countries_and_attack_groups", {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => res.json())
      .then((result) => {
        const topCountries = result.top_countries
          .slice(0, 5)
          .map((country) => ({
            name: country.key,
            visits: country.doc_count,
            iso_code: country.iso_code,
            latitude: country.latitude,
            longitude: country.longitude,
          }));

        setCountryData(topCountries);

        const topAttacks = result.top_attack_groups
          .slice(0, 5)
          .map((attack) => ({
            name: attack.key.substring(0, 20) + "...",
            percentage: attack.doc_count,
          }));

        setSourceData(topAttacks);

        // Update sourceChartData with the doc_count values from top_attacks
        const topAttacksChartData = result.top_attack_groups
          .slice(0, 5)
          .map((attack) => attack.doc_count);
        setSourceChartData(topAttacksChartData);

        // Set markers using the topCountries data

        const topMarkers = result.top_countries.map((country) => ({
          name: country.key,
          visits: country.doc_count,
          iso_code: country.iso_code,
          latitude: country.latitude,
          longitude: country.longitude,
        }));

        const markers = topMarkers.map((country) => ({
          name: country.name,
          visits: country.visits,
          coords: [country.latitude, country.longitude],
        }));

        setMarkers(markers);
        setLoading(false);
      });

    // (unchanged code)
  }, []);
  function getChartOptions() {
    var themeColor = getComputedStyle(document.body)
      .getPropertyValue("--bs-theme")
      .trim();
    var themeColorRgb = getComputedStyle(document.body)
      .getPropertyValue("--bs-theme-rgb")
      .trim();

    return {
      donut: {
        colors: [
          "rgba(" + themeColorRgb + ", .15)",
          "rgba(" + themeColorRgb + ", .35)",
          "rgba(" + themeColorRgb + ", .55)",
          "rgba(" + themeColorRgb + ", .75)",
          "rgba(" + themeColorRgb + ", .95)",
        ],
        chart: { sparkline: { enabled: true } },
        stroke: { show: false },
        tooltip: {
          x: { show: false },
          y: {
            title: {
              formatter: function (seriesName) {
                return "";
              },
            },
          },
        },
      },
    };
  }
  function showCustomTooltip(e, el, code) {
    // Prepare the content for the custom tooltip
    var regionName = el.html();
    var customTooltipContent = "<strong>" + regionName + "</strong>";

    // Find or create the custom tooltip element
    var tooltip = document.getElementById("customTooltip");
    if (!tooltip) {
      tooltip = document.createElement("div");
      tooltip.id = "customTooltip";
      tooltip.style.display = "none";
      document.body.appendChild(tooltip);
    }

    // Set the content and show the tooltip
    tooltip.innerHTML = customTooltipContent;
    tooltip.style.display = "block";
    tooltip.style.left = e.pageX + 10 + "px";
    tooltip.style.top = e.pageY + 10 + "px";

    // Hide the tooltip when the mouse leaves the region
    el.mouseout(function () {
      tooltip.style.display = "none";
    });
  }

  function renderMap() {
    var theme = getComputedStyle(document.body)
      .getPropertyValue("--bs-theme")
      .trim();
    var inverse = getComputedStyle(document.body)
      .getPropertyValue("--bs-inverse")
      .trim();
    var inverseRgb = getComputedStyle(document.body)
      .getPropertyValue("--bs-inverse-rgb")
      .trim();
    var bodyFontFamily = getComputedStyle(document.body)
      .getPropertyValue("--bs-body-font-family")
      .trim();
    const map = document.getElementById("jvectorMap");
    const mapElm = document.querySelectorAll(".jvm-tooltip");

    if (map) {
      new jsVectorMap({
        selector: "#jvectorMap",
        map: "world",
        onRegionTipShow: function (e, el, code) {
          console.log(e);
          e.preventDefault(); // Prevent the default tooltip from showing up
          showCustomTooltip(e, el, code);
        },
        zoomButtons: true,
        normalizeFunction: "polynomial",
        hoverOpacity: 0.5,
        hoverColor: true,
        zoomOnScroll: false,
        series: {
          regions: [
            {
              normalizeFunction: "polynomial",
            },
          ],
        },

        focusOn: {
          x: 0.5,
          y: 0.5,
          scale: 1,
        },
        markers: markers,
        markerStyle: {
          initial: {
            fill: theme,
            stroke: "none",
            r: 3,
          },
          hover: {
            fill: theme,
          },
        },

        markerStyle: {
          initial: {
            fill: theme,
            stroke: "none",
            r: 2,
            fillOpacity: 0.8,
          },
          hover: {
            fill: theme,
            // fillOpacity: 0.9, // Add this line
          },
        },

        regionStyle: {
          initial: {
            fill: inverse,
            fillOpacity: 0.15,
            stroke: "none",
            strokeWidth: 0.4,
            strokeOpacity: 1,
          },
          hover: {
            fillOpacity: 0.5,
          },
        },
        backgroundColor: "transparent",
      });
    }
  }

  useEffect(() => {
    renderMap();

    document.addEventListener("theme-reload", () => {
      setChartOptions(getChartOptions());
      renderMap();
    });

    // eslint-disable-next-line
  }, [markers]);
  useEffect(() => {
    return function cleanUp() {
      context.setAppHeaderNone(false);
      context.setAppSidebarNone(false);
      context.setAppContentClass("");
      // setDate(null);
    };
  }, [context]);

  return (
    <div className="ratio ratio-21x9">
      {!loading ? (
        <div id="jvectorMap" className="jvectormap-without-padding"></div>
      ) : (
        <div className="text-center">Loading...</div>
      )}
    </div>
  );
}

export default AttackMap;

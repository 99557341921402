import React, { useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";
import Cookie from "js-cookie";
import * as echarts from "echarts";
import { Link } from "react-router-dom";

function DashboardStats({ dashboardData }) {
  const [data, setData] = useState([]);

  useEffect(() => {}, []);

  return (
    <>
      <div className="row text-center h-100">
        <div className="col-6 col-6 d-flex flex-column align-items-right justify-content-center my-2">
          <Link to="/agents" className="text-decoration-none">
            <div>
              <div class="d-flex fw-bold small mb-1">
                <span class="flex-grow-1 text-muted">DEVICES</span>
              </div>
              <div>
                <div>
                  <h3 class="mb-0 text-muted">{dashboardData.total_agents}</h3>
                </div>
              </div>
            </div>
          </Link>
        </div>
        <div className="col-6 col-6 d-flex flex-column align-items-center justify-content-center my-2">
          <div>
            <div class="d-flex fw-bold small mb-1">
              <span class="flex-grow-1 text-danger">CRITICAL TODAY</span>
            </div>
            <div>
              <div>
                <h3 class="mb-0 text-danger">
                  {dashboardData.critical_events24h}
                </h3>
              </div>
            </div>
          </div>
        </div>
        <div className="col-6 col-6 d-flex flex-column align-items-center justify-content-center my-2">
          <div>
            <div class="d-flex fw-bold small mb-1">
              <span class="flex-grow-1 text-warning">EVENTS TODAY</span>
            </div>
            <div>
              <div>
                <h3 class="mb-0 text-warning">
                  {dashboardData.total_alerts_24h}
                </h3>
              </div>
            </div>
          </div>
        </div>

        <div className="col-6 d-flex flex-column align-items-center justify-content-center my-2">
          <div>
            <div class="d-flex fw-bold small mb-1">
              <span class="flex-grow-1 text-warning">TOTAL EVENTS</span>
            </div>
            <div>
              <div>
                <h3 class="mb-0 text-warning">{dashboardData.events}</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DashboardStats;

import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { SearchQueryContext } from "../../context/SearchQueryContext";
import { AppSettings } from "../../config/app-settings";
import Cookie from "js-cookie";
import AgentCard from "../../components/agent/AgentCard";
import { Card, CardBody } from "./../../components/card/card.jsx";
import RealTimeLogsDashboard from "../../components/activity/RealTimeLogsDashboard";
import Dashboard24HoursChart from "../../components/charts/Dashboard24HoursChart";
import Dashboard30DaysChart from "../../components/charts/Dashboard30DaysChart";
import ComplianceDashboardChart from "../../components/charts/ComplianceDashboardChart";
import { FilterContext } from "../../context/FilterContext";
import FileIntegrityMonitoring from "../../components/activity/FileIntegrityMonitoring";
import RootCheckMonitoring from "../../components/activity/RootCheckMonitoring";
import SCAMonitoring from "../../components/activity/SCAMonitoring";
import VulnerabilityManagement from "../../components/activity/VulnerabilityManagement";

function AgentDetail({}) {
  const { id: agentId } = useParams();
  const [agents, setAgents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [datat, setDate] = useState(null);
  const context = useContext(AppSettings);
  const [searchQuery] = useContext(SearchQueryContext);
  const [logs, setLogs] = useState([]);
  const [filterLevel, setFilterLevel] = useState(13);
  const [currentPage, setCurrentPage] = useState(1);
  const [logsPerPage, setLogsPerPage] = useState(20);
  const [group, setGroup] = useState(null);
  const [ipAddressFilter, setIpAddressFilter] = useState(null);
  const [alertLevel, setAlertLevel] = useState(0);
  const [filter, setFilter] = useContext(FilterContext);

  const [selectedLogIndex, setSelectedLogIndex] = useState(null);

  useEffect(() => {
    async function fetchAgents() {
      const token = Cookie.get("token");
      try {
        const response = await fetch("https://app.semon.io:8443/agents", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error(`Error fetching agents list: ${response.statusText}`);
        }
        const data = await response.json();
        if (data) {
          const filteredData = agentId
            ? data.filter((agent) => agent.id === agentId)
            : data;
          setAgents(filteredData);
        }
      } catch (error) {
        console.error("Error fetching agents list:", error);
      } finally {
        // console.log(agents);
      }
    }
    fetchAgents();
  }, []);

  useEffect(() => {
    async function fetchLogs() {
      const token = Cookie.get("token");
      // Pagination parameters
      const offsetParam = `offset=${logsPerPage * (currentPage - 1)}`;
      const limitParam = `limit=${logsPerPage}`;

      // Other parameters
      const startDateParam =
        datat && datat.startDate
          ? `&from_date=${datat.startDate.format("YYYY-MM-DD")}`
          : "";
      const endDateParam =
        datat && datat.endDate
          ? `&to_date=${datat.endDate.format("YYYY-MM-DD")}`
          : "";
      const agentIdParam = agentId ? `&agent_id=${agentId}` : "";
      if (group) {
        const groupParam = `&rule_groups=${group}`;
      }
      const groupParam = group ? `&rule_groups=${group}` : "";
      const ipAddressFilterData = ipAddressFilter
        ? `&ip_address=${ipAddressFilter}`
        : "";
      const alertLevelData = alertLevel ? `&alert_level=${alertLevel}` : "";

      const mitreIdsData = filter.mitreIds
        ? `&mitre_ids=${filter.mitreIds.join(",")}`
        : "";

      const { agent_name, attacker_ip, country } = filter;
      const agentNameParam = agent_name ? `&agent_name=${agent_name}` : "";
      const attackerIpParam = attacker_ip ? `&attacker_ip=${attacker_ip}` : "";
      const countryParam = country ? `&country=${country}` : "";

      try {
        const response = await fetch(
          `https://app.semon.io:8443/alerts?${offsetParam}&${limitParam}${agentIdParam}${startDateParam}${endDateParam}${groupParam}${ipAddressFilterData}${alertLevelData}${mitreIdsData}${attackerIpParam}${countryParam}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error(`Error fetching logs list: ${response.statusText}`);
        }

        const data = await response.json();
        if (data) {
          const filteredData = data.filter((log) =>
            log.agent.name.toLowerCase().includes(searchQuery.toLowerCase())
          );
          setLogs(filteredData);
          setLoading(false);
          // console.log(filteredData);
        } else {
          setLogs([]);
        }
      } catch (error) {
        console.error("Error fetching agents list:", error);
        setLogs([]);
      } finally {
        // console.log("finally");
        setLoading(false);
      }
    }
    fetchLogs();
    let intervalId = setInterval(fetchLogs, 3000);
    return function cleanUp() {
      context.setAppHeaderNone(false);
      context.setAppSidebarNone(false);
      context.setAppContentClass("");
      clearInterval(intervalId);
      // setDate(null);
    };
  }, [
    agents[0],
    currentPage,
    logsPerPage,
    group,
    searchQuery,
    datat,
    ipAddressFilter,
    alertLevel,
    filter,
  ]);

  if (loading) {
    return (
      <div className="loading-container">
        <div className="loading-bar" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="row">
        <div className="col-xl-3 col-lg-3">
          <AgentCard agent={agents[0]} style={{ height: "300px" }} />
        </div>
        <div className="col-xl-3 col-lg-3 mb-4">
          <Card>
            <CardBody>
              <small className="row justify-content-center opacity-5">
                Events past 24 hours
              </small>
              <Dashboard24HoursChart />
            </CardBody>
          </Card>
        </div>
        <div className="col-xl-3 col-lg-3  mb-4">
          <Card className="align-items-stretch" style={{ height: "100%" }}>
            <CardBody className="align-items-stretch">
              <small className="row justify-content-center opacity-5">
                Attacks past 30 days
              </small>
              <Dashboard30DaysChart />
            </CardBody>
          </Card>
        </div>
        <div className="col-xl-3 col-lg-3  mb-4">
          <Card>
            <CardBody>
              <small className="row justify-content-center opacity-5">
                Regulatory Compliance
              </small>
              <ComplianceDashboardChart />
            </CardBody>
          </Card>
        </div>
      </div>

      <div className="row">
        <div className="col-xl-12 col-lg-12">
          <Card>
            <ul className="nav nav-tabs nav-tabs-v2 ps-4 pe-4">
              <li className="nav-item me-3">
                <a
                  href="#threatdetection"
                  className="nav-link active"
                  data-bs-toggle="tab"
                  onClick={() => {
                    setLogs([]);
                    setGroup(null);
                  }}
                >
                  Threat detection
                </a>
              </li>
              <li className="nav-item me-3">
                <a
                  href="#integritydetection"
                  className="nav-link"
                  data-bs-toggle="tab"
                  onClick={() => {
                    setLogs([]);
                    setGroup("syscheck");
                  }}
                >
                  Integrity Monitoring
                </a>
              </li>
              <li className="nav-item me-3">
                <a
                  href="#rootcheckdetection"
                  className="nav-link"
                  data-bs-toggle="tab"
                  onClick={() => {
                    setLogs([]);
                    setGroup("rootcheck");
                  }}
                >
                  Breach Detection
                </a>
              </li>
              <li className="nav-item me-3">
                <a
                  href="#scadetection"
                  className="nav-link"
                  data-bs-toggle="tab"
                  onClick={() => {
                    setLogs([]);
                    setGroup("sca");
                  }}
                >
                  Security Assessment
                </a>
              </li>
              <li className="nav-item me-3">
                <a
                  href="#vulnerabilitydetection"
                  className="nav-link"
                  data-bs-toggle="tab"
                  onClick={() => {
                    setLogs([]);
                    setGroup("vulnerability-detector");
                  }}
                >
                  Vulnerability Management
                </a>
              </li>
              <li className="nav-item me-3">
                <a
                  href="#pci_dss"
                  className="nav-link"
                  data-bs-toggle="tab"
                  onClick={() => {
                    setLogs([]);
                    setGroup("pci_dss");
                  }}
                >
                  PCI DSS
                </a>
              </li>
              <li className="nav-item me-3">
                <a
                  href="#gdpr"
                  className="nav-link"
                  data-bs-toggle="tab"
                  onClick={() => {
                    setLogs([]);
                    setGroup("gdpr");
                  }}
                >
                  GDPR
                </a>
              </li>
              <li className="nav-item me-3">
                <a
                  href="#hipaa"
                  className="nav-link"
                  data-bs-toggle="tab"
                  onClick={() => {
                    setLogs([]);
                    setGroup("hipaa");
                  }}
                >
                  HIPAA
                </a>
              </li>
            </ul>
            {/* Tab navs... */}
            <div className="tab-content p-4">
              <div className="tab-pane fade show active" id="threatdetection">
                <RealTimeLogsDashboard
                  logs={logs}
                  loading={loading}
                  setDate={setDate}
                  datat={datat}
                  ipAddressFilter={filter.attacker_ip}
                  setIpAddressFilter={setFilter}
                  alertLevel={alertLevel}
                  setAlertLevel={setAlertLevel}
                />
              </div>
              <div className="tab-pane fade" id="integritydetection">
                {group == "syscheck" ? (
                  <FileIntegrityMonitoring
                    logs={logs}
                    loading={loading}
                    setDate={setDate}
                    datat={datat}
                    ipAddressFilter={ipAddressFilter}
                    setIpAddressFilter={setIpAddressFilter}
                  />
                ) : (
                  ""
                )}
              </div>
              <div className="tab-pane fade" id="rootcheckdetection">
                {group == "rootcheck" ? (
                  <RootCheckMonitoring
                    logs={logs}
                    loading={loading}
                    setDate={setDate}
                    datat={datat}
                    ipAddressFilter={ipAddressFilter}
                    setIpAddressFilter={setIpAddressFilter}
                  />
                ) : (
                  ""
                )}
              </div>
              <div className="tab-pane fade" id="scadetection">
                {group == "sca" ? (
                  <SCAMonitoring
                    agentId={agentId}
                    logsPerPage={logsPerPage}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                  />
                ) : (
                  ""
                )}
              </div>
              <div className="tab-pane fade" id="vulnerabilitydetection">
                {group == "vulnerability-detector" ? (
                  <VulnerabilityManagement
                    logs={logs}
                    loading={loading}
                    setDate={setDate}
                    datat={datat}
                    ipAddressFilter={ipAddressFilter}
                    setIpAddressFilter={setIpAddressFilter}
                  />
                ) : (
                  ""
                )}
              </div>
              <div className="tab-pane fade" id="pci_dss">
                {group == "pci_dss" ? (
                  <RealTimeLogsDashboard
                    logs={logs}
                    loading={loading}
                    setDate={setDate}
                    ipAddressFilter={ipAddressFilter}
                    setIpAddressFilter={setIpAddressFilter}
                  />
                ) : (
                  ""
                )}
              </div>
              <div className="tab-pane fade" id="gdpr">
                {group == "gdpr" ? (
                  <RealTimeLogsDashboard
                    logs={logs}
                    loading={loading}
                    setDate={setDate}
                    datat={datat}
                    ipAddressFilter={ipAddressFilter}
                    setIpAddressFilter={setIpAddressFilter}
                  />
                ) : (
                  ""
                )}
              </div>
              <div className="tab-pane fade" id="hipaa">
                {group == "hipaa" ? (
                  <RealTimeLogsDashboard
                    logs={logs}
                    loading={loading}
                    setDate={setDate}
                    datat={datat}
                    ipAddressFilter={ipAddressFilter}
                    setIpAddressFilter={setIpAddressFilter}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          </Card>
          <div className="row">
            <div className="col-xl-12 col-lg-12 mt-4">
              {/* Tab navs... */}
              {/* Pagination... */}
              <div className="d-flex justify-content-between align-items-center mb-3">
                <ul
                  className="pagination d-flex align-items-center"
                  style={{ width: "100%" }}
                >
                  <li className="me-auto" style={{ padding: "4px" }}>
                    <select
                      id="logsPerPage"
                      className="form-select"
                      value={logsPerPage}
                      onChange={(e) => setLogsPerPage(Number(e.target.value))}
                    >
                      <option value={10}>10</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                      <option value={500}>500</option>
                    </select>
                  </li>
                  <li
                    className={`page-item ${
                      currentPage === 1 ? "disabled" : ""
                    }`}
                    style={{ padding: "4px" }}
                  >
                    <a
                      className="page-link"
                      onClick={() =>
                        setCurrentPage(currentPage > 1 ? currentPage - 1 : 1)
                      }
                      style={{ cursor: "pointer" }}
                    >
                      Previous
                    </a>
                  </li>
                  <li
                    style={{ padding: "4px" }}
                    className="d-flex align-items-center px-4"
                  >
                    <span>Page {currentPage}</span>
                  </li>
                  <li className={`page-item `} style={{ padding: "4px" }}>
                    <a
                      className="page-link"
                      onClick={() => setCurrentPage(currentPage + 1)}
                      style={{ cursor: "pointer" }}
                    >
                      Next
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AgentDetail;

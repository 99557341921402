import React, { useContext, useEffect, useState } from "react";
import { SearchQueryContext } from "../../context/SearchQueryContext";
import { Link } from "react-router-dom";
import { FilterContext } from "../../context/FilterContext";
import Cookie from "js-cookie";
import { use } from "echarts";

function SCAMonitoring(props) {
  const [searchQuery] = useContext(SearchQueryContext);
  const [logs, setLogs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [logsPerPage, setLogsPerPage] = useState(props.logsPerPage);
  const [agentId, setAgentId] = useState(props.agentId);
  useEffect(() => {
    console.log(props.logsPerPage);
    async function fetchLogs() {
      const token = Cookie.get("token");
      // Pagination parameters
      const offsetParam = `&offset=${
        props.logsPerPage * (props.currentPage - 1)
      }`;
      const limitParam = `limit=${props.logsPerPage}`;

      // Other parameters

      const agentIdParam = agentId ? `&agent_id=${agentId}` : "";

      try {
        const response = await fetch(
          `https://app.semon.io:8443/sca_alerts?${limitParam}${offsetParam}${agentIdParam}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error(`Error fetching logs list: ${response.statusText}`);
        }

        const data = await response.json();
        if (data) {
          const filteredData = data.filter((log) =>
            log.agent.name.toLowerCase().includes(searchQuery.toLowerCase())
          );
          setLogs(filteredData);
          // console.log(filteredData);
        } else {
          setLogs([]);
        }
      } catch (error) {
        console.error("Error fetching agents list:", error);
        setLogs([]);
      } finally {
        // console.log("finally");
      }
    }
    fetchLogs();
  }, [props.currentPage, props.logsPerPage]);

  function flattenJSONtoHTML(data, parentKey = "", isRoot = true) {
    let htmlElements = [];
    for (let key in data) {
      let newKey = parentKey ? `${parentKey}.${key}` : key;
      if (typeof data[key] !== "object" || data[key] === null) {
        htmlElements.push(
          <React.Fragment key={newKey}>
            <dt className="col-sm-3 text-break p-2">{newKey}:</dt>
            <dd className="col-sm-9 text-break p-2">{data[key]?.toString()}</dd>
          </React.Fragment>
        );
      }
    }

    for (let key in data) {
      let newKey = parentKey ? `${parentKey}.${key}` : key;
      if (typeof data[key] === "object" && data[key] !== null) {
        if (isRoot) {
          htmlElements.push(
            <p className="separator" key={`p-${newKey}`}>
              {newKey}
            </p>
          );
        }
        htmlElements = [
          ...htmlElements,
          ...flattenJSONtoHTML(data[key], newKey, false),
        ];
      }
    }

    return htmlElements;
  }
  useEffect(() => {
    console.log("lpp", props.logsPerPage);
  }, [props.logsPerPage]);

  return (
    <div className=" mb-3">
      <div>
        <div className="row mb-4 align-middle fw-bold small">
          <h1 class="page-header flex-grow-1 col-1">
            Security Assessment{" "}
            <small>Real-Time CIS Benchmark compliance results</small>
          </h1>

          <div className="col-auto align-middle">
            <Link
              to="/realtime"
              className="text-inverse text-opacity-50 text-decoration-none"
            >
              <i className="bi bi-fullscreen"></i>
            </Link>
          </div>
        </div>

        <div className="table-responsive">
          <table className="table table-striped table-borderless mb-2px small text-wrap p-4">
            <tbody>
              {logs && logs.length > 0 ? (
                logs.map((log, index) => (
                  <tr key={log.id}>
                    <td className="px-2 py-4">
                      {log.data.sca.check.result === "failed" ? (
                        <span className="text-red">
                          <i class="fas fa-lg fa-fw me-2 fa-minus-square"></i>
                        </span>
                      ) : log.data.sca.check.result === "passed" ? (
                        <span className="text-success">
                          <i
                            className={"fas fa-lg fa-fw me-2 fa-check-square"}
                          ></i>
                        </span>
                      ) : (
                        <span>
                          <i
                            className={"fas fa-lg fa-fw me-2 fa-check-square"}
                          ></i>
                        </span>
                      )}
                    </td>
                    <td className="p-4 px-0">
                      <h6>{log.data.sca.check.title}</h6>

                      <p className="p-1">
                        <strong className="opacity-60">Description: </strong>{" "}
                        {log.data.sca.check.rationale}
                      </p>
                      {log.data.sca &&
                        log.data.sca.check &&
                        log.data.sca.check.remediation && (
                          <div className="remediation-wrapper">
                            <p className="p-1">
                              <strong className="opacity-60">
                                Remediation:{" "}
                              </strong>
                              {log.data.sca.check.remediation}
                            </p>
                          </div>
                        )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="4">No threats found</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {/* Add this after the closing </table> tag */}
      </div>
    </div>
  );
}

export default SCAMonitoring;

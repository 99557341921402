import React, { useContext, useEffect, useRef, useState } from "react";
import { Toast } from "bootstrap";
import { FilterContext } from "../../context/FilterContext";

export default function ClearFiltersButton() {
  const [filter, setFilter] = useContext(FilterContext);

  const toastRef = useRef(null);
  const [toast, setToast] = useState(null);
  const [position, setPosition] = useState({ left: "0px", top: "0px" });

  useEffect(() => {
    const toastTrigger1 = document.getElementById("showToast1");
    const toast1 = document.getElementById("toast-1");
    const toastWidth = 200; // Replace this with the actual width of your toast
    const toastHeight = 100; // Replace this with the actual height of your toast
    if (toastTrigger1) {
      toastTrigger1.addEventListener("click", (e) => {
        let left = e.clientX;
        let top = e.clientY;

        // Adjust position if the toast would overflow the window
        if (left + toastWidth > window.innerWidth) {
          left = window.innerWidth - 300;
        }
        if (top + toastHeight > window.innerHeight) {
          top = window.innerHeight - 200;
        }

        setPosition({ left: `${left}px`, top: `${top}px` });

        const myToast = new Toast(toast1);
        myToast.show();
        setToast(myToast);
      });
    }
  });

  const clearFilters = () => {
    setFilter([]); // Call setFilter with default value to clear filters
    if (toast) toast.hide();
  };

  const hideToast = () => {
    if (toast) toast.hide();
  };

  return (
    <>
      <i
        className="fas fa-lg fa-fw me-2 fa-trash"
        style={{ cursor: "pointer", zIndex: "99" }}
        id="showToast1"
      ></i>

      <div
        className="toasts-container hide"
        style={{
          zIndex: "98",
          position: "fixed",
          ...position,
        }}
      >
        <div
          className="toast fade hide"
          data-autohide="false"
          data-bs-autohide="false"
          id="toast-1"
        >
          <div className="toast-header">
            <i className="far fa-bell text-muted me-2"></i>
            <strong className="me-auto">Alert</strong>

            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="toast"
            ></button>
          </div>
          <div className="toast-body">
            Are you sure you want to clear all filters?
            {/* add buttons */}
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-outline-theme m-2"
                onClick={clearFilters}
              >
                Yes
              </button>
              <button className="btn btn-outline-theme m-2" onClick={hideToast}>
                No
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

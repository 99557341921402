import React, { useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";
import Cookie from "js-cookie";
import * as echarts from "echarts";

function ComplianceDashboardChart() {
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchComplianceAlertCounts();
  }, []);

  async function fetchComplianceAlertCounts() {
    const token = Cookie.get("token");

    try {
      const response = await fetch(
        `https://app.semon.io:8443/get_compliance_alert_counts`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(
          `Error fetching compliance alert counts: ${response.statusText}`
        );
      }

      const fetchedData = await response.json();
      const processedData = Object.entries(fetchedData).map(
        ([name, value]) => ({ name, value })
      );

      setData(processedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  const option = {
    grid: {
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      containLabel: true,
    },
    tooltip: {
      trigger: "item",
      backgroundColor: "rgba(0,0,0, 0.8)",
      borderColor: "rgba(255,255,255, 0.2)",
      borderWidth: 1,
      padding: [5, 10],
      textStyle: {
        color: "#a8b6bc",
      },
      formatter: function (params) {
        return (
          params.name.toUpperCase() +
          "<br/>Events: " +
          params.value +
          " (" +
          params.percent +
          "%)"
        );
      },
    },
    series: [
      {
        name: "Compliance",
        type: "pie",
        radius: "50%",
        data: data,
        label: {
          color: "#fff",
          textBorderColor: "transparent",
          textBorderWidth: 0,
        },
        itemStyle: {
          color: (params) => {
            const colors = [
              "rgba(30, 204, 51, 1)",
              "rgba(30, 204, 51, 0.80)",
              "rgba(30, 204, 51, 0.60)",
              "rgba(30, 204, 51, 0.40)",
              "rgba(30, 204, 51, 0.20)",
            ];
            return colors[params.dataIndex % colors.length];
          },
          shadowBlur: 10,
          shadowColor: "rgba(0, 0, 0, 0.4)",
        },
      },
    ],
  };

  return (
    <ReactECharts
      option={option}
      style={{ height: "200px", width: "100%", padding: "0", margin: "0" }}
    />
  );
}

export default ComplianceDashboardChart;

import React, { useEffect, useState, useRef, useContext } from "react";
import ReactECharts from "echarts-for-react";
import Cookie from "js-cookie";
import * as echarts from "echarts/core";
import { FilterContext } from "../../context/FilterContext";

function TopAttackCountriesChart({ top_attacking_countries }) {
  const [data, setData] = useState([]);
  const echartRef = useRef(null);
  const [filter, setFilter] = useContext(FilterContext);

  useEffect(() => {
    if (top_attacking_countries) {
      const processedData = Object.entries(top_attacking_countries)
        .map(([key, value]) => ({
          name: key,
          value: Math.log(value),
        }))
        .sort((a, b) => a.value - b.value); // Sorting in ascending order

      setData(processedData);
    }
  }, [top_attacking_countries]);

  useEffect(() => {
    if (echartRef.current) {
      const instance = echartRef.current.getEchartsInstance();
      const clickHandler = function (params) {
        const item = data.find((d) => d.name === params.name);
        if (item) {
          setFilter((prevFilter) => ({
            ...prevFilter,
            country: item.name,
          }));
        } else {
          console.log("Clicked bar name not found in data:", params.name);
        }
      };
      instance.on("click", clickHandler);

      // cleanup function
      return () => {
        instance.off("click", clickHandler);
      };
    }
  }, [echartRef, data]);

  const option = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
      backgroundColor: "rgba(0,0,0, 0.8)",
      borderColor: "rgba(255,255,255, 0.2)",
      borderWidth: 1,
      padding: [5, 10],
      textStyle: {
        color: "#a8b6bc",
      },
      extraCssText: "box-shadow: 0 0 10px rgba(255, 255, 255, 0.1);", // Add blur effect
      formatter: function (params) {
        const truncatedName = params[0].axisValue;
        const item = data.find((d) => d.name === truncatedName);
        var tooltipText = item.name + "<br/>"; // This will be the full name
        tooltipText +=
          "Events: " + Math.exp(params[0].value).toFixed(0) + "<br/>"; // Adding "Events: doc_count" but reverting the logarithm for display purposes
        return tooltipText;
      },
    },
    grid: {
      top: "10px",
      left: "30px",
      right: "20px",
      bottom: "0px",
      containLabel: true,
    },
    xAxis: {
      type: "value",
      show: false,
      axisTick: {
        show: false, // Hide X axis ticks
      },
    },
    yAxis: {
      type: "category",
      data: data.map((item) => item.name),
      axisLabel: {
        fontSize: 10, // adjust the font size as needed
        color: "rgba(30, 204, 51, 0.6)",
      },
    },
    series: [
      {
        name: "Top Attack Countries",
        type: "bar",
        barWidth: "50%", // Set the bar width. Adjust this value as needed
        data: data.map((item) => item.value),
        barCategoryGap: "10px",
        itemStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            { offset: 0, color: "rgba(30, 204, 51, 0.85)" },
            { offset: 1, color: "rgba(106, 121, 133, 0.4)" },
          ]),
          borderWidth: 0.9,
          borderColor: "rgba(30, 204, 51, 0.6)",
          shadowColor: "rgba(30, 204, 51, 0.4)",
          shadowBlur: 10,
          shadowOffsetY: 4,
        },
      },
    ],
  };

  return (
    <ReactECharts
      ref={echartRef}
      option={option}
      style={{ height: "100%", width: "100%" }}
    />
  );
}

export default TopAttackCountriesChart;

import React from "react";
import { Button, Offcanvas } from "react-bootstrap";
import "./RightSidebar.css";

function RightSidebar(props) {
  return (
    <>
      <i
        className={"fas fa-lg fa-fw me-2 fa-search-plus " + props.opacity}
        onClick={props.onToggle}
      ></i>

      <Offcanvas
        show={props.isOpen}
        onHide={props.onToggle}
        placement="end"
        backdrop={false}
        className="opacity-9 offcanvas-container"
        style={{}}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{props.agent}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>{props.children}</Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default RightSidebar;

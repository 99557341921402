import React, { useContext, useEffect, useState } from "react";

import Cookie from "js-cookie";
import { SearchQueryContext } from "../../context/SearchQueryContext";
import AgentCard from "../../components/agent/AgentCard";
import AttackMap from "../../components/agent/AttackMap";

function Agents() {
  const [searchQuery, setSearchQuery] = useContext(SearchQueryContext);

  const [allAgents, setAllAgents] = useState([]);
  const [agents, setAgents] = useState([]);
  const [loading, setLoading] = useState(true); // set initial state to true
  const [currentPage, setCurrentPage] = useState(1);
  const [agentsPerPage, setAgentsPerPage] = useState(12);

  const { isAuthenticated } = true;

  useEffect(() => {
    const { innerWidth, innerHeight } = window;
    if (innerHeight < 900) {
      setAgentsPerPage(8);
    } else {
      setAgentsPerPage(16);
    }
    async function fetchAgents(searchQuery) {
      const token = Cookie.get("token");
      try {
        const response = await fetch("https://app.semon.io:8443/agents", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error(`Error fetching agents list: ${response.statusText}`);
        }
        const data = await response.json();
        if (data) {
          setAllAgents(data);
          const filteredData = data.filter((agent) =>
            agent.name.toLowerCase().includes(searchQuery.toLowerCase())
          );
          setAgents(filteredData);
        }
      } catch (error) {
        console.error("Error fetching agents list:", error);
      } finally {
        setLoading(false); // set loading state to false when data is loaded
      }
    }
    fetchAgents(searchQuery);
  }, [isAuthenticated, searchQuery]);

  const indexOfLastAgent = currentPage * agentsPerPage;
  const indexOfFirstAgent = indexOfLastAgent - agentsPerPage;
  const currentAgents = agents.slice(indexOfFirstAgent, indexOfLastAgent);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(agents.length / agentsPerPage); i++) {
    pageNumbers.push(i);
  }

  function handlePageChange(page) {
    setCurrentPage(page);
    if (window.innerWidth < 768) {
      window.scrollTo(0, 0);
    }
  }

  return (
    <div>
      <ul className="breadcrumb">
        <li className="breadcrumb-item">
          <a href="#/">Semon.IO</a>
        </li>
        <li className="breadcrumb-item active">End-points</li>
      </ul>

      <div>
        {loading ? ( // display loading spinner while loading is true
          <div className="loading-container">
            <div className="loading-bar" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          <div className="row">
            {currentAgents.map((agent, index) => (
              <div className="col-xl-3 col-lg-6" key={index}>
                <AgentCard agent={agent} />
              </div>
            ))}
          </div>
        )}

        {!loading ? (
          <div className="row">
            <ul className="pagination justify-content-end">
              <li
                className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
              >
                <a
                  className="page-link"
                  onClick={() => handlePageChange(currentPage - 1)}
                  style={{ cursor: "pointer" }}
                >
                  Previous
                </a>
              </li>
              {pageNumbers.map((number) => (
                <li
                  className={`page-item ${
                    number === currentPage ? "active" : ""
                  }`}
                  key={number}
                  style={{ cursor: "pointer" }}
                >
                  <a
                    className="page-link"
                    onClick={() => handlePageChange(number)}
                    style={{ cursor: "pointer" }}
                  >
                    {number}
                  </a>
                </li>
              ))}
              <li
                className={`page-item ${
                  currentPage === pageNumbers.length ? "disabled" : ""
                }`}
              >
                <a
                  className="page-link"
                  onClick={() => handlePageChange(currentPage + 1)}
                  style={{ cursor: "pointer" }}
                >
                  Next
                </a>
              </li>
            </ul>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default Agents;
